<div class="grid">
  <!-- Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <span [pTooltip]="!!checkConnectionQuota ? '' : 'Maximum number of connections reached'" tooltipPosition="bottom" appendTo="body">
          <p-button
            severity="primary"
            [label]="'Connect To New Network'"
            [rounded]="true"
            icon="pi pi-plus"
            (onClick)="openConnectionForm()"
            [disabled]="!!isLoading || !checkConnectionQuota" />
        </span>
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedConnection }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [(firstIndex)]="firstIndex"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedConnections"
        [(rowSingleSelection)]="selectedConnection"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'id'"
        itemName="Network"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6" *ngIf="!!selectedConnection">
    <div class="container-border">
      <microsec-loading-overlay [isLoading]="!!isLoading">
        <microsec-selected-panel-header
          [id]="selectedConnection.id"
          [label]="selectedConnection.name"
          (closeSelectedPanelEvent)="hideDetailsPanel()"
          [rightTemplate]="rightTemplate"></microsec-selected-panel-header>
        <ng-template #rightTemplate>
          <p-button
            severity="primary"
            icon="fas fa-edit"
            pTooltip="Edit"
            tooltipPosition="bottom"
            appendTo="body"
            (onClick)="openConnectionForm(selectedConnection)"
            *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
          </p-button>
        </ng-template>
        <microsec-card [isDetailCard]="true">
          <p-tabView styleClass="microsec-dashboard-tab">
            <p-tabPanel header="Overview">
              <app-network-overview [connection]="selectedConnection" />
            </p-tabPanel>
            <p-tabPanel header="Topics" *ngIf="!!selectedConnection?.topics?.length || !!selectedConnection?.interface?.topics?.length">
              <app-topics-field
                #topicsFieldComponent
                [isReadOnly]="true"
                [topics]="selectedConnection?.topics || selectedConnection?.interface?.topics || []"></app-topics-field>
            </p-tabPanel>
          </p-tabView>
        </microsec-card>
      </microsec-loading-overlay>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedConnections)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedConnections.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getConnections()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <i
        class="pi"
        [ngClass]="{
          'pi-check-circle text-green': rowData.status === STATUSES.CONNECTED,
          'pi-times-circle text-red': rowData.status === STATUSES.CONNECTION_ERROR,
          'pi-minus-circle text-400': rowData.status === STATUSES.NOT_CONNECTED
        }"
        [pTooltip]="rowData.status"
        tooltipPosition="bottom"
        appendTo="body">
      </i>
    </ng-container>
    <!-- Enabled -->
    <ng-container *ngSwitchCase="'enabled'">
      <!-- <p-checkbox [(ngModel)]="rowData.enabled" [binary]="true" [disabled]="true"></p-checkbox> -->
      <p-inputSwitch
        (click)="$event.stopPropagation()"
        [(ngModel)]="rowData[col.field]"
        (onChange)="updateConnection(rowData)"
        [disabled]="rowData?.interface?.type === CONNECTION_TYPES.NODE"
        [pTooltip]="
          rowData?.interface?.type === CONNECTION_TYPES.NODE
            ? 'Disabling MicroIDS Node network is not supported.\n\nDelete MicroIDS Node network to stop data forwarding from MicroIDS Node.'
            : ''
        "
        tooltipPosition="bottom"
        appendTo="body" />
    </ng-container>
    <!-- Interface Type -->
    <ng-container *ngSwitchCase="'interfaceType'">
      {{ rowData.interface?.type ? CONNECTION_TYPE_LABELS[rowData.interface.type] || rowData.interface.type : '-' }}
    </ng-container>
    <!-- Protocols -->
    <ng-container *ngSwitchCase="'protocols'">
      @if (!!rowData.protocols?.length) {
        <ng-container *ngFor="let protocol of rowData.protocols; let lastProtocol = last">
          {{ protocol | uppercase }}<span *ngIf="!lastProtocol">,&nbsp;</span>
        </ng-container>
      } @else {
        <span>-</span>
      }
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
