export const THREAT_STATUSES = {
  OPEN: 'Open',
  FIXING: 'Fixing',
  CLOSED_VERIFIED_THREAT: 'Closed (Verified Threat)',
  CLOSED_FALSE_ALARM: 'Closed (False Alarm)',
};

export const THREAT_STATUS_COLORS = {
  OPEN: '#FB4D58',
  FIXING: '#FF9534',
  CLOSED_VERIFIED_THREAT: '#00BB83',
  CLOSED_FALSE_ALARM: '#888888',
};

export const THREAT_STATUS_OPTIONS = [
  { label: THREAT_STATUSES.OPEN, value: THREAT_STATUSES.OPEN, dataValue: THREAT_STATUSES.OPEN, isTruePositive: null, tooltip: '' },
  { label: THREAT_STATUSES.FIXING, value: THREAT_STATUSES.FIXING, dataValue: THREAT_STATUSES.FIXING, isTruePositive: true, tooltip: '' },
  {
    label: THREAT_STATUSES.CLOSED_VERIFIED_THREAT,
    value: THREAT_STATUSES.CLOSED_VERIFIED_THREAT,
    dataValue: 'Closed',
    isTruePositive: true,
    tooltip: 'Threat is closed and marked as verified threat',
  },
  {
    label: THREAT_STATUSES.CLOSED_FALSE_ALARM,
    value: THREAT_STATUSES.CLOSED_FALSE_ALARM,
    dataValue: 'Closed',
    isTruePositive: false,
    tooltip: 'Threat is closed and marked as false alarm',
  },
];

export const THREAT_TYPES = {
  ANOMALY: 'Anomaly',
  MALWARE: 'Malware',
  VULNERABILITY: 'Vulnerability',
  NON_COMPLIANCE: 'Non_compliance',
};

export const THREAT_TYPE_COLORS = {
  ANOMALY: '#7D3AC1',
  MALWARE: '#DB4CB2',
  VULNERABILITY: '#9089FA',
};

export const THREAT_TYPE_OPTIONS = [
  { label: THREAT_TYPES.ANOMALY, value: THREAT_TYPES.ANOMALY },
  { label: THREAT_TYPES.MALWARE, value: THREAT_TYPES.MALWARE },
  { label: THREAT_TYPES.VULNERABILITY, value: THREAT_TYPES.VULNERABILITY },
  { label: 'Non-Compliance', value: THREAT_TYPES.NON_COMPLIANCE },
];

export const THREAT_TYPE_DESCRIPTIONS = {
  [THREAT_TYPES.ANOMALY]: 'an anomaly',
  [THREAT_TYPES.MALWARE]: 'a malware',
  [THREAT_TYPES.VULNERABILITY]: 'a vulnerability',
};

export const ATTACK_TYPE_OPTIONS = [
  { label: 'DoS Attack', value: 'dos_attack' },
  { label: 'Jamming Attack', value: 'jamming_attack' },
  { label: 'Tampering Attack', value: 'tampering_attack' },
  { label: 'Replay Attack', value: 'replay_attack' },
  { label: 'Injection Attack', value: 'injection_attack' },
  { label: 'Malware', value: 'malware' },
  { label: 'Vulnerability', value: 'vulnerability' },
  { label: 'Configuration Vulnerability', value: 'configuration_vulnerability' },
  { label: 'Firmware Vulnerability', value: 'firmware_vulnerability' },
  { label: 'Package Vulnerability', value: 'package_vulnerability' },
  { label: 'Protocol Vulnerability', value: 'protocol_vulnerability' },
  { label: 'Default Password Vulnerability', value: 'default_password_vulnerability' },
  { label: 'Command Anomaly', value: 'command anomaly' },
  { label: 'Package Anomaly', value: 'package_anomaly' },
  { label: 'Downgrade Attack', value: 'downgrade_attack' },
  { label: 'Authentication Attack', value: 'authentication_attack' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Others', value: 'others' },
];

export const MITIGATION_STATUSES = {
  OPEN: 'Open',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
  ACTED: 'Acted',
  UNACTED: 'Unacted',
};

export const MITIGATION_STATUS_OPTIONS = [
  { value: MITIGATION_STATUSES.OPEN, label: MITIGATION_STATUSES.OPEN },
  { value: MITIGATION_STATUSES.ACTIVE, label: MITIGATION_STATUSES.ACTIVE },
  { value: MITIGATION_STATUSES.CLOSED, label: MITIGATION_STATUSES.CLOSED },
  { value: MITIGATION_STATUSES.ACTED, label: 'Active/Completed' },
  { value: MITIGATION_STATUSES.UNACTED, label: 'Open' },
];

export const THREAT_SCORE_OPTIONS = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

export const MITIGATION_MODES = {
  WORKFLOW: 'workflow',
  RECOMMEND: 'recommend',
};

export const MITIGATION_MODE_OPTIONS = [
  { label: 'Automated Workflow', value: MITIGATION_MODES.WORKFLOW },
  { label: 'Recommendations', value: MITIGATION_MODES.RECOMMEND },
];
