import { CONNECTION_FEATURES } from './feature';

export const CONNECTION_TYPES = {
  CONTINUOUS_SCAN: 'continuousScan',
  PHYSICAL: 'physical',
  NODE: 'microids',
  PCAP: 'pcap',
  MQTT_AGENT: 'mqtt-agent',
  MQTT: 'mqtt',
};

export const CONNECTION_TYPE_LABELS = {
  [CONNECTION_TYPES.CONTINUOUS_SCAN]: 'Continuous Scan',
  [CONNECTION_TYPES.PHYSICAL]: 'Local Scan',
  [CONNECTION_TYPES.NODE]: 'MicroIDS Node',
  [CONNECTION_TYPES.PCAP]: 'PCAP Analysis',
  [CONNECTION_TYPES.MQTT_AGENT]: 'MicroAgent',
  [CONNECTION_TYPES.MQTT]: 'MQTT Broker',
};

export const CONNECTION_TYPE_SPECIFICATIONS = {
  REAL_TIME: 'Real-Time Analysis',
  DURATION_SPECIFIC: 'Duration-Specific Analysis',
  DEVICE_MANAGEMENT: 'Device Management',
  NETWORK_MONITORING: 'Network Monitoring',
};

export const CONNECTION_TYPE_OPTIONS = [
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.CONTINUOUS_SCAN],
    description: 'Continous Network Scanning',
    specifications: [CONNECTION_TYPE_SPECIFICATIONS.REAL_TIME, CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING],
    value: CONNECTION_TYPES.CONTINUOUS_SCAN,
    icon: 'fas fa-circle-nodes',
    easeOfSetup: 4,
    nonIntrusionIndex: 2,
  },
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.PCAP],
    description: 'One Time Network Capture',
    specifications: [CONNECTION_TYPE_SPECIFICATIONS.DURATION_SPECIFIC, CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING],
    value: CONNECTION_TYPES.PCAP,
    icon: 'fa fa-file',
    easeOfSetup: 4,
    nonIntrusionIndex: 4,
    feature: CONNECTION_FEATURES.PCAP,
  },
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.MQTT_AGENT],
    description: 'Device Management & Host IDS',
    specifications: [
      CONNECTION_TYPE_SPECIFICATIONS.REAL_TIME,
      `${CONNECTION_TYPE_SPECIFICATIONS.DEVICE_MANAGEMENT} & ${CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING}`,
    ],
    value: CONNECTION_TYPES.MQTT_AGENT,
    icon: 'microsec',
    easeOfSetup: 1,
    nonIntrusionIndex: 1,
    feature: CONNECTION_FEATURES.MICRO_AGENT,
  },
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.MQTT],
    description: 'Connect MQTT Broker to MicroIDS',
    specifications: [CONNECTION_TYPE_SPECIFICATIONS.REAL_TIME, CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING],
    value: CONNECTION_TYPES.MQTT,
    icon: 'fa fa-cloud',
    easeOfSetup: 2,
    nonIntrusionIndex: 3,
    feature: CONNECTION_FEATURES.MQTT,
  },
];

export const CONTINUOUS_SCAN_OPTIONS = [
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.PHYSICAL],
    description: 'Scans Local Network',
    specifications: [CONNECTION_TYPE_SPECIFICATIONS.REAL_TIME, CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING],
    value: CONNECTION_TYPES.PHYSICAL,
    icon: 'fas fa-circle-nodes',
    easeOfSetup: 4,
    nonIntrusionIndex: 3,
    feature: CONNECTION_FEATURES.PHYSICAL,
  },
  {
    label: CONNECTION_TYPE_LABELS[CONNECTION_TYPES.NODE],
    description: 'Connect to Distributed MicroIDS',
    specifications: [CONNECTION_TYPE_SPECIFICATIONS.REAL_TIME, CONNECTION_TYPE_SPECIFICATIONS.NETWORK_MONITORING],
    value: CONNECTION_TYPES.NODE,
    icon: 'microsec',
    easeOfSetup: 2,
    nonIntrusionIndex: 2,
    feature: CONNECTION_FEATURES.MICRO_IDS,
  },
];

export const STATUSES = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not connected',
  CONNECTION_ERROR: 'connection error',
};

export const MQTT_AUTHENTICATIONS = {
  ANONYMOUS: 'anonymous',
  USERNAME_PASSWORD: 'username-password',
  KEY_CERTIFICATE: 'key-certificate-agent',
};

export const MQTT_AUTHENTICATION_OPTIONS = [
  { label: 'Anonymous', value: MQTT_AUTHENTICATIONS.ANONYMOUS },
  { label: 'Username/Password', value: MQTT_AUTHENTICATIONS.USERNAME_PASSWORD },
  { label: 'Key/Certificate', value: MQTT_AUTHENTICATIONS.KEY_CERTIFICATE },
];

export const MICROAGENT_AGENT_VERSION_OPTIONS = [
  {
    value: '1.36.13',
    label: '1.36.13',
  },
];

export const MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS = [
  {
    value: 'openssl-native',
    label: 'OpenSSL (native)',
  },
];

export const MICROAGENT_FEATURES = {
  PROTECTION_MONITORING: 'protection-monitoring',
  PROTECTION: 'protection',
  NIDS_HIDS: 'nids-hids',
  HIDS: 'hids',
  NIDS: 'nids',
};

export const MICROAGENT_FEATURE_OPTIONS = [
  {
    value: MICROAGENT_FEATURES.PROTECTION_MONITORING,
    label: 'Protection & Monitoring (HIDS & NIDS)',
    cryptographicEngine: MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS,
  },
  {
    value: MICROAGENT_FEATURES.PROTECTION,
    label: 'Protection',
    cryptographicEngine: MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS,
  },
  {
    value: MICROAGENT_FEATURES.NIDS_HIDS,
    label: 'Monitoring (HIDS & NIDS)',
    cryptographicEngine: [],
  },
  {
    value: MICROAGENT_FEATURES.HIDS,
    label: 'Monitoring (HIDS)',
    cryptographicEngine: [],
  },
  {
    value: MICROAGENT_FEATURES.NIDS,
    label: 'Monitoring (NIDS)',
    cryptographicEngine: [],
  },
];

export const MICROAGENT_ARCHITECTURES = {
  AMD64: 'amd64',
  ARM64: 'arm64',
  ARMHF: 'armhf',
};

export const MICROAGENT_ARCHITECTURE_OPTIONS = [
  {
    value: MICROAGENT_ARCHITECTURES.AMD64,
    label: MICROAGENT_ARCHITECTURES.AMD64,
    features: MICROAGENT_FEATURE_OPTIONS,
  },
  {
    value: MICROAGENT_ARCHITECTURES.ARM64,
    label: MICROAGENT_ARCHITECTURES.ARM64,
    features: MICROAGENT_FEATURE_OPTIONS,
  },
  {
    value: MICROAGENT_ARCHITECTURES.ARMHF,
    label: MICROAGENT_ARCHITECTURES.ARMHF,
    features: MICROAGENT_FEATURE_OPTIONS,
  },
];

export const MICROAGENT_OPERATING_SYSTEMS = {
  LINUX_DEBIAN: 'linux-debian',
  LINUX_OPENWRT: 'linux-openwrt',
  FREERTOS: 'freertos-esp-idf-v5.0',
  BARE: 'bare-metal-generic-sdk',
};

export const MICROAGENT_OPERATING_SYSTEM_OPTIONS = [
  {
    value: MICROAGENT_OPERATING_SYSTEMS.LINUX_DEBIAN,
    label: 'Linux | Debian',
    architectures: MICROAGENT_ARCHITECTURE_OPTIONS,
  },
  {
    value: MICROAGENT_OPERATING_SYSTEMS.LINUX_OPENWRT,
    label: 'Linux | OpenWrt',
    architectures: MICROAGENT_ARCHITECTURE_OPTIONS,
  },
  {
    value: MICROAGENT_OPERATING_SYSTEMS.FREERTOS,
    label: 'FreeRTOS | ESP-IDF (v5.0)',
    architectures: [],
    disabled: true,
  },
  {
    value: MICROAGENT_OPERATING_SYSTEMS.BARE,
    label: 'Bare Metal | Generic SDK',
    architectures: [],
    disabled: true,
  },
];

export const MICROAGENT_FILES = {
  [MICROAGENT_OPERATING_SYSTEMS.LINUX_DEBIAN]: {
    [MICROAGENT_ARCHITECTURES.AMD64]: {
      [MICROAGENT_FEATURES.PROTECTION_MONITORING]: 'micro-edge-agent.protection-monitoring.openssl-native_1.36.13_amd64_linux.deb',
      [MICROAGENT_FEATURES.PROTECTION]: 'micro-edge-agent.protection.openssl-native_1.36.13_amd64_linux.deb',
      [MICROAGENT_FEATURES.NIDS_HIDS]: 'micro-edge-agent.nids-hids_1.36.13_amd64_linux.deb',
      [MICROAGENT_FEATURES.HIDS]: 'micro-edge-agent.hids_1.36.13_amd64_linux.deb',
      [MICROAGENT_FEATURES.NIDS]: 'micro-edge-agent.nids_1.36.13_amd64_linux.deb',
    },
    [MICROAGENT_ARCHITECTURES.ARM64]: {
      [MICROAGENT_FEATURES.PROTECTION_MONITORING]: 'micro-edge-agent.protection-monitoring.openssl-native_1.36.13_arm64_linux.deb',
      [MICROAGENT_FEATURES.PROTECTION]: 'micro-edge-agent.protection.openssl-native_1.36.13_arm64_linux.deb',
      [MICROAGENT_FEATURES.NIDS_HIDS]: 'micro-edge-agent.nids-hids_1.36.13_arm64_linux.deb',
      [MICROAGENT_FEATURES.HIDS]: 'micro-edge-agent.hids_1.36.13_arm64_linux.deb',
      [MICROAGENT_FEATURES.NIDS]: 'micro-edge-agent.nids_1.36.13_arm64_linux.deb',
    },
    [MICROAGENT_ARCHITECTURES.ARMHF]: {
      [MICROAGENT_FEATURES.PROTECTION_MONITORING]: 'micro-edge-agent.protection-monitoring.openssl-native_1.36.13_armhf_linux.deb',
      [MICROAGENT_FEATURES.PROTECTION]: 'micro-edge-agent.protection.openssl-native_1.36.13_armhf_linux.deb',
      [MICROAGENT_FEATURES.NIDS_HIDS]: 'micro-edge-agent.nids-hids_1.36.13_armhf_linux.deb',
      [MICROAGENT_FEATURES.HIDS]: 'micro-edge-agent.hids_1.36.13_armhf_linux.deb',
      [MICROAGENT_FEATURES.NIDS]: 'micro-edge-agent.nids_1.36.13_armhf_linux.deb',
    },
  },
  [MICROAGENT_OPERATING_SYSTEMS.LINUX_OPENWRT]: {
    [MICROAGENT_ARCHITECTURES.AMD64]: {
      [MICROAGENT_FEATURES.PROTECTION]: 'micro-edge-agent.protection.openssl-native_1.36.13_amd64_openwrt.ipk',
    },
    [MICROAGENT_ARCHITECTURES.ARM64]: {
      [MICROAGENT_FEATURES.PROTECTION]: 'micro-edge-agent.protection.openssl-native_1.36.13_arm64_openwrt.ipk',
    },
  },
};

export const MQTT_AGENT_BROKERS = {
  BUILT_IN: 'built-in',
  EXTERNAL: 'external',
};

export const MQTT_AGENT_BROKER_OPTIONS = [
  { label: 'MicroIDS Built-In Broker', value: MQTT_AGENT_BROKERS.BUILT_IN },
  { label: 'External MQTT / LCMS RA Broker', value: MQTT_AGENT_BROKERS.EXTERNAL },
];

export const MQTT_AGENT_BUILTIN_DEFAULT_VALUES: { [key: string]: any } = {
  ['name']: 'microids-built-in-broker',
  ['description']: 'Built-in MicroIDS Broker',
  ['broker_hostname']: 'mosquitto',
  ['broker_port']: 8883,
  ['is_tls']: false,
  ['is_anonymous']: false,
  ['broker_username']: 'CN=internal',
};
