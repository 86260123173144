@if (!!connection) {
  <microsec-card [isNestedCard]="true">
    <div class="grid">
      <label class="col-12 font-bold"> Connection Details </label>
      <div class="col-12">
        <div class="list-fields mx-3">
          <div class="grid align-items-center">
            <label class="col-4">Enabled</label>
            <div class="col-8">
              <p-inputSwitch [(ngModel)]="connection.enabled" [disabled]="true"></p-inputSwitch>
            </div>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Name</label>
            <label class="col-8">{{ connection.name }}</label>
          </div>
          <div class="grid align-items-center" *ngIf="!!connection.description">
            <label class="col-4">Description</label>
            <label class="col-8">{{ connection.description }}</label>
          </div>
          @if (connection?.interface?.type !== CONNECTION_TYPES.NODE && !!connection?.protocols?.length) {
            <div class="grid align-items-center">
              <label class="col-4">Protocols</label>
              <label class="col-8">
                <ng-container *ngFor="let protocol of connection.protocols; let lastProtocol = last">
                  {{ protocol | uppercase }}<span *ngIf="!lastProtocol">,&nbsp;</span>
                </ng-container>
              </label>
            </div>
          }
          <div class="grid align-items-center">
            <label class="col-4">Interface</label>
            <label class="col-8">{{ (connection?.interface?.type | constant: 'connection-interface-type') || '-' }}</label>
          </div>
          @if (connection?.interface?.type === CONNECTION_TYPES.PHYSICAL && !!connection?.interface?.identifier) {
            <div class="grid align-items-center">
              <label class="col-4">Interface Identifier</label>
              <label class="col-8">{{ connection?.interface?.identifier }}</label>
            </div>
          }
          @if (connection?.interface?.type === CONNECTION_TYPES.MQTT || connection?.interface?.type === CONNECTION_TYPES.MQTT_AGENT) {
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.broker_hostname && !mqttAgentBuiltin">
              <label class="col-4">Hostname</label>
              <label class="col-8">{{ connection.interface.broker_hostname }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.broker_port && !mqttAgentBuiltin">
              <label class="col-4">Port</label>
              <label class="col-8">{{ connection.interface.broker_port }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!mqttAgentBuiltin">
              <label class="col-4">Is TLS</label>
              <div class="col-8">
                <p-checkbox [(ngModel)]="connection?.interface.is_tls" [binary]="true" [disabled]="true"></p-checkbox>
              </div>
            </div>
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.broker_client_cert_origin_filename">
              <label class="col-4">Client Certificate Filename</label>
              <label class="col-8">{{ connection.interface.broker_client_cert_origin_filename }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.broker_client_key_origin_filename">
              <label class="col-4">Client Key Filename</label>
              <label class="col-8">{{ connection.interface.broker_client_key_origin_filename }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.tls_certificate_origin_filename">
              <label class="col-4">Certificate Filename</label>
              <label class="col-8">{{ connection.interface.tls_certificate_origin_filename }}</label>
            </div>
          }
          @if (connection?.interface?.type === CONNECTION_TYPES.PCAP) {
            <div class="grid align-items-center" *ngIf="!!connection?.interface?.origin_filename">
              <label class="col-4">PCAP Filename</label>
              <label class="col-8">{{ connection.interface.origin_filename }}</label>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Reload PCAP</label>
              <div class="col-8">
                <p-checkbox [(ngModel)]="connection.reload_pcap" [binary]="true" [disabled]="true"></p-checkbox>
              </div>
            </div>
          }
          <div class="grid align-items-center">
            <label class="col-4">Status</label>
            <div class="col-8">
              <i
                class="pi"
                [ngClass]="{
                  'pi-check-circle text-green': connection.status === STATUSES.CONNECTED,
                  'pi-times-circle text-red': connection.status === STATUSES.CONNECTION_ERROR,
                  'pi-minus-circle text-400': connection.status === STATUSES.NOT_CONNECTED
                }"
                [pTooltip]="connection.status"
                tooltipPosition="bottom"
                appendTo="body">
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </microsec-card>
}
