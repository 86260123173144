import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { UserService } from '@microsec/services';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS } from '@microsec/constants';
import { FormItem, User } from '@microsec/models';
import { firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccessTokenDetailComponent } from '../access-token-detail/access-token-detail.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConnectionService } from '@ids-services';

const FORM_PARAMS = {
  NAME: 'name',
  EXPIRY_DATE: 'expiry_date',
};

@Component({
  selector: 'app-access-token-form',
  templateUrl: './access-token-form.component.html',
  styleUrls: ['./access-token-form.component.scss'],
})
export class AccessTokenFormComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  currentUser: User | null = null;

  connection: any = null;

  constructor(
    private userSrv: UserService,
    private connectionSrv: ConnectionService,
    private dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit() {
    this.connection = this.dialogConfig.data?.connection;
    this.currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    const expiryDateSelectEvent = new EventEmitter<any>();
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: "Access token's name",
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EXPIRY_DATE,
        label: 'Expiry Date',
        field: 'calendar',
        calendarMinDate: new Date(),
        onDateSelectedEvent: expiryDateSelectEvent,
        fieldInfo: 'Expiry date of token. If expiry date is blank, the token will never expire',
        defaultValue: null,
      } as FormItem),
    ];
    expiryDateSelectEvent.subscribe((selectedDate: Date) => {
      selectedDate.setHours(23, 59, 59, 0);
    });
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: (result: any) => void) {
    const payload = { ...this.form.getRawValue() };
    this.userSrv
      .createUserToken(this.currentUser?.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.showSuccessMessage(CREATE_SUCCESS.replace('{0}', 'access token'));
          if (!!this.connection) {
            this.connectionSrv
              .updateConnection(this.connection?.id, {
                project_id: this.breadcrumbConfig?.projectId,
                name: this.connection?.name,
                description: this.connection?.description,
                enabled: this.connection?.enabled,
                interface: { type: this.connection?.interface?.type, access_token_id: rs?.id },
              })
              .subscribe({
                next: (connectionRs: any) => {
                  this.connection.interface.access_token_id = connectionRs?.data?.interface?.access_token_id;
                  this.openTokenDetails(rs?.token);
                  closeDialog(this.connection);
                },
                error: (err) => {
                  this.showErrorMessage(err);
                },
              });
          } else {
            this.openTokenDetails(rs?.token);
            closeDialog(false);
          }
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open token details
   * @param token
   */
  openTokenDetails(token: string) {
    this.dialogSrv.open(AccessTokenDetailComponent, {
      data: {
        token,
      },
      header: 'Access Token',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }
}
