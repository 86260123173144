<microsec-card [isBordered]="!!isBordered" [isNoPadding]="!isBordered" styleClass="overflow-x-hidden" [isFull]="true">
  <div class="grid h-full">
    <!-- HEADER -->
    @if (!!header) {
      <div class="col-12">
        <div class="flex w-full">
          <div class="font-bold mb-3">{{ header }}</div>
        </div>
      </div>
    }
    @if (!!isScoreValid()) {
      <div class="col-12">
        <div class="flex flex-column">
          <div class="text-data font-bold mb-3" [ngClass]="{ 'py-6 text-center': selectedPercentileValue() === null }">
            <span class="text-7xl" [ngClass]="{ 'text-8xl': selectedPercentileValue() === null }" [ngStyle]="{ color: scoreColor() }">{{
              score | number: '1.0-0'
            }}</span>
            <span class="text-3xl" [ngClass]="{ 'text-4xl': selectedPercentileValue() === null }">%</span>
          </div>
          @if (selectedPercentileValue() !== null) {
            <div class="flex flex-row flex-wrap text-data">
              <div
                class="font-bold"
                [ngStyle]="{
                  color: percentileColor(),
                  backgroundColor: getDarkerColor(percentileColor(), 0.75),
                  padding: '0.25rem',
                  borderRadius: '3px'
                }">
                Outranking {{ selectedPercentileValue() }}%
              </div>
              @if (!!breadcrumbConfig?.projectId) {
                <div class="flex align-items-center">
                  &nbsp;of {{ selectedPercentile() !== 'Null' ? selectedPercentile() : UNDEFINED_INDUSTRY_OPTION }} companies
                </div>
              } @else {
                <div class="flex align-items-center">
                  @if (selectedPercentile() === DEFAULT_INDUSTRY_OPTION) {
                    <span>&nbsp;of companies across&nbsp;</span>
                  } @else {
                    <span>&nbsp;of&nbsp;</span>
                  }
                  <span class="font-bold cursor-pointer" (click)="itemsList.toggle($event)">
                    <span> {{ selectedPercentile() !== 'Null' ? selectedPercentile() : UNDEFINED_INDUSTRY_OPTION }}</span>
                    @if (selectedPercentile() !== DEFAULT_INDUSTRY_OPTION) {
                      <span class="font-normal">&nbsp;companies&nbsp;</span>
                    }
                    <i class="fa fa-chevron-down ml-1"></i>
                  </span>
                </div>
                <p-menu #itemsList styleClass="microsec-button-context-menu w-max" [model]="percentilesArr()" [popup]="true" appendTo="body" />
              }
            </div>
          } @else if (!breadcrumbConfig?.projectId) {
            <div class="flex flex-row flex-wrap text-data">
              <div class="flex align-items-center">No benchmark score for&nbsp;</div>
              <div class="flex align-items-center">
                <span class="font-bold cursor-pointer" (click)="itemsList.toggle($event)">
                  <span> {{ selectedPercentile() !== 'Null' ? selectedPercentile() : UNDEFINED_INDUSTRY_OPTION }}</span>
                  @if (selectedPercentile() !== DEFAULT_INDUSTRY_OPTION) {
                    <span class="font-normal">&nbsp;companies&nbsp;</span>
                  }
                  <i class="fa fa-chevron-down ml-1"></i>
                </span>
                <p-menu #itemsList styleClass="microsec-button-context-menu w-max" [model]="percentilesArr()" [popup]="true" appendTo="body" />
              </div>
            </div>
          }
        </div>
      </div>
      <!-- CHART -->
      @if (selectedPercentileValue() !== null) {
        <div class="col-12">
          <canvas #diagram></canvas>
        </div>
      }
      <!-- DESCRIPTION -->
      @if (!!description) {
        <div class="col-12 text-data" [innerHTML]="htmlDesc()"></div>
      }
    } @else {
      <div class="col-12 text-center">No data found</div>
    }
  </div>
</microsec-card>
