/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, BehaviorSubject, Observable, of, scheduled } from 'rxjs';

import { IThreatService } from './IThreatService';
import threatMitigationData from './mock-data/threat-mitigations.json';
import * as threatsData from './mock-data/threats2.json';
import * as threatGroupsData from './mock-data/threat-groups.json';
import * as threatSummaryData from './mock-data/threat-summary.json';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { Store } from '@ngrx/store';
import { PROJECT_MANAGEMENT_CONSTANTS, THREAT_STATUSES } from '@ids-constants';

@Injectable({
  providedIn: 'root',
})
export class MockThreatService implements IThreatService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedObs: Observable<any> = this.selected$.asObservable();

  constructor(private store: Store) {}

  createThreat(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreats(config: any): Observable<any> {
    return scheduled([threatsData as any], asyncScheduler);
  }
  getThreat(id: number): Observable<any> {
    const threat = (threatsData?.threats as any[]).find((p) => p.threat_id === id);
    return scheduled([threat], asyncScheduler);
  }
  updateThreat(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteThreat(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAggregateThreatScore(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatSummary(config: any): Observable<any> {
    return scheduled([threatSummaryData as any], asyncScheduler);
  }
  setThreatsCounter(organizationId: any, projectId: any): void {
    if (projectId) {
      const isGrouping = JSON.parse(localStorage.getItem('isThreatGroup') || 'true');
      const request: Observable<any> = !!isGrouping
        ? this.getThreatGroups({ organizationId, projectId, page: 1, perPage: 1 })
        : this.getThreats({ projectId: projectId, detailed: false, status: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING] });
      request.pipe().subscribe({
        next: (res) => {
          this.store.dispatch(new fromLayoutActions.AddLeftNavbarBadge(PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE, res?.total_record));
        },
      });
    }
  }
  createThreatMitigation(threat_id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatMitigation(threat_id: number): Observable<any[]> {
    return of([...(threatMitigationData as any[])]);
  }
  updateMitigation(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteMitigation(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatSetting(project_id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateThreatSetting(project_id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatGroups(config: any): Observable<any> {
    return scheduled([threatGroupsData as any], asyncScheduler);
  }
  getThreatGroup(id: number): Observable<any> {
    const threat = (threatGroupsData?.threat_groups as any[]).find((p) => p.group_id === id);
    return scheduled([threat], asyncScheduler);
  }
  updateThreatGroup(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteThreatGroup(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatGroupDevices(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
