import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ATTACK_TYPE_OPTIONS, THREAT_SCORE_OPTIONS, THREAT_STATUSES, THREAT_STATUS_OPTIONS, THREAT_TYPES } from '@ids-constants';
import { ConstantPipe } from '@ids-pipes';
import { ConnectionService, ThreatService } from '@ids-services';
import { CommonTableComponent } from '@microsec/components';
import { DELETE_LABEL, PER_PAGE, UPDATE_LABEL } from '@microsec/constants';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ReportSpecification } from '@microsec/models';
import moment from 'moment';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';

const FIELDS = {
  updated: 'End Date',
  created: 'Start Date',
  group_id: 'ID',
  description: 'Description',
  detected_on: 'Detected on',
  status: 'Status',
  threat_type: 'Threat Type',
  name: 'Name',
  threat_score: 'Threat Score',
  threat_count: 'Threat Count',
  attack_type: 'Attack Type',
  non_compliance_category: 'Non-Compliance Category',
  attack_technique: 'Attack Technique',
  mitre_technique_id: 'Mitre Technique ID',
  org_id: 'Organization ID',
  project_id: 'Project ID',
  snort_rule_id: 'Snort Rule ID',
  analyzer_id: 'Analyzer ID',
  user_id: 'User ID',
  connection_id: 'Connection ID',
  affected_device_count: 'Device Count',
};

const FILTER_CONFIGURATION: CommonToolbarConfiguration = {
  types: ['search', 'filter'],
  searchPlaceholder: 'Search Threat Group ID, MITRE Technique ID, Comment or Description...',
  filters: {
    1: {
      key: 'attackTypes',
      label: 'Attack Type',
      type: 'multiselect',
      options: ATTACK_TYPE_OPTIONS,
    },
    2: {
      key: 'connections',
      label: 'Connection',
      type: 'multiselect',
      options: [],
    },
    4: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    6: {
      key: 'statuses',
      label: 'Status',
      defaultValue: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
      type: 'multiselect',
      options: THREAT_STATUS_OPTIONS,
    },
    7: {
      key: 'threatScores',
      label: 'Threat Score',
      type: 'multiselect',
      options: THREAT_SCORE_OPTIONS,
    },
    9: {
      key: 'updatedDates',
      label: 'Updated Date',
      type: 'date-range',
    },
  },
};

@Component({
  selector: 'app-threat-group-list',
  templateUrl: './threat-group-list.component.html',
  styleUrls: ['./threat-group-list.component.scss'],
  providers: [ConstantPipe],
})
export class ThreatGroupListComponent extends BaseComponent implements OnInit {
  isLoading = false;

  rowStatusButtonItems: MenuItem[] = [];

  statusButtonItems: MenuItem[] = [];

  statusAllButtonItems: MenuItem[] = [];

  @Input() switchTemplate?: TemplateRef<any>;

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('rowStatusEditPanel') rowStatusEditPanel!: OverlayPanel;

  values: any[] = [];

  currentPage = 1;

  totalRecords = 0;

  selectedThreatGroups: any[] = [];

  selectedThreatGroup: any = null;

  cols: any[] = [
    { field: 'name', header: FIELDS.name, width: 12 },
    { field: 'created', header: FIELDS.created, width: 12 },
    { field: 'updated', header: FIELDS.updated, width: 12 },
    { field: 'statistics', header: 'Statistics', width: 10 },
    { field: 'threat_score', header: FIELDS.threat_score, width: 15 },
    { field: 'status', header: FIELDS.status, width: 15 },
  ];

  selectedCols: any[] = [];

  get reportSelectedCols() {
    let results = this.util.cloneObjectArray(this.selectedCols);
    if (!!results.find((p) => p.field === 'statistics')) {
      results.push(
        ...[
          { field: 'affected_device_count', header: 'Device Count', width: 10 },
          { field: 'threat_count', header: 'Threat Count', width: 10 },
        ],
      );
    }
    results = results.filter((p) => p.field !== 'statistics');
    return results;
  }

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = this.cols.filter((col) => value?.includes(col.field));
  }

  filters: {
    [key: string]: any;
  } = {};

  filterConfiguration: CommonToolbarConfiguration = this.util.cloneDeepObject(FILTER_CONFIGURATION);

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  actionsMenuItems: ActionMenuItem[] = [];

  THREAT_TYPES = THREAT_TYPES;

  threatStatusOptions = this.util.cloneObjectArray(THREAT_STATUS_OPTIONS);

  constructor(
    public threatSrv: ThreatService,
    private connectionSrv: ConnectionService,
    private constantPipe: ConstantPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Change Status',
        icon: 'fa fa-list-check',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        items: this.util.cloneObjectArray(THREAT_STATUS_OPTIONS).map((statusOption) => ({
          label: statusOption.label,
          disabled: ({ rowData }) => rowData.status === statusOption.dataValue && rowData.is_true_positive === statusOption.isTruePositive,
          command: ({ rowData }) => this.openUpdateStatusConfirmation([rowData], statusOption),
        })),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.filters['statuses'] = FILTER_CONFIGURATION?.filters?.[6].defaultValue || [];
    this.getThreatGroups();
    this.getConnections();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.threatSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getThreatGroups();
      }
    });
    this.subscriptions.push(subscription);
    this.initFilterConfiguration();
  }

  initFilterConfiguration() {
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getThreatGroups();
      }
    });
  }

  getThreatGroups(resetSelectedThreatGroups = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.threatSrv
      .getThreatGroups({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        page,
        perPage,
        sort: this.dt?.datatable?.sortField || 'id',
        reserve: this.dt?.datatable?.sortOrder ? this.dt?.datatable?.sortOrder === 1 : true,
        status: this.filters?.['statuses'],
        search: this.filters?.['search'],
        connectionId: this.filters?.['connections'],
        attackType: this.filters?.['attackTypes'],
        threatScore: !!this.filters?.['threatScores']?.length
          ? THREAT_SCORE_OPTIONS.filter((item) => ((this.filters?.['threatScores'] as any[]) || []).find((filter) => filter === item.value)).map(
              (param) => param.value,
            )
          : this.filters?.['threatScores'],
        createdFrom: this.filters?.['createdFrom'] ? (moment(this.filters?.['createdFrom']).toISOString() as any) : null,
        createdTo: this.filters?.['createdTo'] ? (moment(this.filters?.['createdTo']).toISOString() as any) : null,
        updatedFrom: this.filters?.['updatedFrom'] ? (moment(this.filters?.['updatedFrom']).toISOString() as any) : null,
        updatedTo: this.filters?.['updatedTo'] ? (moment(this.filters?.['updatedTo']).toISOString() as any) : null,
        isTruePositive: this.filters?.['isTruePositive'],
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          this.values = ((res.threat_groups as any[]) || []).map((v) => ({
            ...v,
            updatableStatus: v.status,
            updatableIsTruePositive: v.is_true_positive,
          }));
          if (!!this.selectedThreatGroup) {
            this.selectedThreatGroup = this.values.find((threat) => threat.group_id === this.selectedThreatGroup.group_id) || null;
          }
          if (!!resetSelectedThreatGroups) {
            this.selectedThreatGroups = [];
          }
          this.setUpdateStatusButtonItems();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getConnections() {
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe()
      .subscribe({
        next: (res) => {
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openUpdateStatusConfirmation(threats: any[] = [], statusOption: any) {
    const toStatusStr = ` to '${statusOption.label}'`;
    const threatList = threats.map((threat) => `<li>${`Threat Group ID: ${threat.group_id}`}</li>`);
    this.confirm({
      action: UPDATE_LABEL,
      objectName: 'Threat Group(s)',
      customContent: `Are you sure you want to update ${
        !threats?.length
          ? `all threat groups${toStatusStr}?<br/><br/>`
          : `the following threat group(s)${toStatusStr}?<ul>${threatList.join('')}</ul>`
      }${
        statusOption.dataValue === 'Closed'
          ? `<br/>Closed threats can be viewed by selecting Filter > Status and choosing '${statusOption.label}'`
          : ''
      }`,
      next: () => {
        if (!threats?.length) {
          this.threatSrv
            .getThreatGroups({
              organizationId: this.breadcrumbConfig?.organizationId,
              projectId: this.breadcrumbConfig?.projectId,
              page: 1,
              perPage: this.totalRecords,
            })
            .pipe()
            .subscribe({
              next: (res) => {
                const allThreats = (res?.threat_groups as any[]) || [];
                this.updateStatusThreats(allThreats, statusOption);
              },
              error: () => {
                this.showErrorMessage('Error while getting all threat groups data');
                this.isLoading = false;
              },
            });
        } else {
          this.updateStatusThreats(threats, statusOption);
        }
      },
      rejectCallback: () => {
        threats.forEach((threat) => {
          threat.updatableStatus = threat.status;
          threat.updatableIsTruePositive = threat.is_true_positive;
        });
      },
    });
  }

  updateStatusThreats(threats: any[], statusOption: any) {
    this.isLoading = true;
    const payload = {
      status: statusOption.dataValue,
      is_true_positive: statusOption.isTruePositive,
    };
    const requests: Observable<any>[] = threats.map((threat) => this.threatSrv.updateThreatGroup(threat.group_id, payload));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Updated status threat group(s) successfully`);
          this.threatSrv.refresh$.next(true);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  deleteThreats(threats: any[]) {
    this.isLoading = true;
    const requests: Observable<any>[] = threats.map((threat) => this.threatSrv.deleteThreatGroup(threat.group_id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Deleted threat group(s) successfully`);
          if (!!this.selectedThreatGroups?.length) {
            this.selectedThreatGroups = [];
          }
          if (!!this.selectedThreatGroup) {
            const threatIds = threats.map((threat) => threat.group_id) || [];
            this.selectedThreatGroup = !!threatIds.includes(this.selectedThreatGroup.group_id) ? null : this.selectedThreatGroup;
          }
          setTimeout(() => {
            this.threatSrv.refresh$.next(true);
          });
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  openDeleteConfirmation(threats: any[] = []) {
    const threatList = threats.map((threat) => `<li>${`Threat Group ID: ${threat.group_id}`}</li>`);
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Threat Group(s)',
      customContent: `Are you sure you want to delete ${
        !threats?.length ? 'all threat groups?<br/><br/>' : `the following threat group(s)?<ul>${threatList.join('')}</ul>`
      }`,
      next: () => {
        if (!threats?.length) {
          this.threatSrv
            .getThreatGroups({
              organizationId: this.breadcrumbConfig?.organizationId,
              projectId: this.breadcrumbConfig?.projectId,
              page: 1,
              perPage: this.totalRecords,
            })
            .pipe()
            .subscribe({
              next: (res) => {
                const allThreats = (res?.threat_groups as any[]) || [];
                this.deleteThreats(allThreats);
              },
              error: () => {
                this.showErrorMessage('Error while getting all threat groups data');
                this.isLoading = false;
              },
            });
        } else {
          this.deleteThreats(threats);
        }
      },
    });
  }

  onClickEditStatusButton(event: any, rowData: any) {
    const rowStatusButtonItems: MenuItem[] = this.util.cloneObjectArray(THREAT_STATUS_OPTIONS).map((statusOption) => ({
      label: statusOption.label,
      disabled: rowData.status === statusOption.dataValue && rowData.is_true_positive === statusOption.isTruePositive,
      command: () => this.openUpdateStatusConfirmation([rowData], statusOption),
    }));
    this.rowStatusButtonItems = rowStatusButtonItems;
    this.rowStatusEditPanel.toggle(event);
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const filename = `project_${this.breadcrumbConfig?.projectId}_threat_groups`;
        if (specification.pageOption === 'current') {
          this.dt.generateReportDialog.exportReport(this.getMappedReportData(this.values), filename);
        } else {
          const requests: Observable<any>[] =
            specification.pageOption === 'custom'
              ? this.util.getSequenceInteger(specification.customPages.from as any, specification.customPages.to as any).map((page) =>
                  this.threatSrv.getThreatGroups({
                    organizationId: this.breadcrumbConfig?.organizationId,
                    projectId: this.breadcrumbConfig?.projectId,
                    page: page,
                    perPage: this.dt?.datatable?.rows || PER_PAGE,
                  }),
                )
              : [
                  this.threatSrv.getThreatGroups({
                    organizationId: this.breadcrumbConfig?.organizationId,
                    projectId: this.breadcrumbConfig?.projectId,
                    page: 1,
                    perPage: this.totalRecords,
                  }),
                ];
          forkJoin(requests).subscribe({
            next: (res) => {
              const data: any[] = [];
              res.forEach((threats) => {
                data.push(...(threats?.threat_groups || []));
              });
              this.dt.generateReportDialog.exportReport(this.getMappedReportData(data), filename);
            },
            error: () => {
              this.showErrorMessage('Error while getting all threat groups data');
              this.dt.generateReportDialog.onClose();
            },
          });
        }
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.reportSelectedCols, []);
  }

  getMappedReportData(data: any[]) {
    return (data || []).map((threat) => ({
      ...threat,
      name:
        threat.threat_type !== THREAT_TYPES.NON_COMPLIANCE
          ? this.constantPipe?.transform(threat.attack_type, 'threat-attack-type')
          : threat.non_compliance_category?.toUpperCase(),
    }));
  }

  assignFilters(value: any) {
    const clonedValue = structuredClone(value);
    if (!!clonedValue?.filter?.statuses?.length) {
      const convertedStatuses: any[] = [];
      const options = this.util.cloneObjectArray(THREAT_STATUS_OPTIONS);
      if (this.filters?.['isTruePositive'] !== null && this.filters?.['isTruePositive'] !== undefined) {
        delete this.filters?.['isTruePositive'];
      }
      let closedStatusCount = 0;
      ((clonedValue?.filter?.statuses as any[]) || []).forEach((status) => {
        switch (status) {
          case THREAT_STATUSES.CLOSED_VERIFIED_THREAT: {
            clonedValue.filter.isTruePositive = true;
            closedStatusCount++;
            break;
          }
          case THREAT_STATUSES.CLOSED_FALSE_ALARM: {
            clonedValue.filter.isTruePositive = false;
            closedStatusCount++;
            break;
          }
          default: {
            break;
          }
        }
        if (closedStatusCount === 2) {
          delete clonedValue.filter.isTruePositive;
        }
        convertedStatuses.push(options.find((p) => p.value === status).dataValue);
      });
      clonedValue.filter.statuses = convertedStatuses.filter((value, index, self) => self.indexOf(value) === index);
    }
    this.filters = {
      ...(clonedValue?.filter || {}),
      search: clonedValue?.search || null,
      createdFrom: clonedValue?.filter?.createdDates?.from || null,
      createdTo: clonedValue?.filter?.createdDates?.to || null,
      updatedFrom: clonedValue?.filter?.updatedDates?.from || null,
      updatedTo: clonedValue?.filter?.updatedDates?.to || null,
    };
  }

  onChangeStatus(event: any) {
    this.openUpdateStatusConfirmation(event.threatGroups, event.statusOption);
  }

  setUpdateStatusButtonItems() {
    const statusAllButtonItems: any[] = [];
    const statusButtonItems: any[] = [];
    this.util.cloneObjectArray(THREAT_STATUS_OPTIONS).forEach((statusOption) => {
      statusAllButtonItems.push({
        label: `${statusOption.label} All`,
        command: () => this.openUpdateStatusConfirmation([], statusOption),
        disabled: !this.values?.length,
      });
      statusButtonItems.push({
        label: `${statusOption.label} Selected`,
        command: () => this.openUpdateStatusConfirmation(this.selectedThreatGroups, statusOption),
      });
    });
    this.statusAllButtonItems = statusAllButtonItems;
    this.statusButtonItems = statusButtonItems;
  }

  get totalRecordStr() {
    return this.totalRecords?.toString() || '0';
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedThreatGroup = null;
  }
}
