import { AfterViewInit, Component, computed, effect, ElementRef, Input, signal, ViewChild, WritableSignal } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { Chart } from 'chart.js';
import { CHART_OPTIONS, getGradientBackgroundColor } from './shared-benchmark.config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NetworkMapHelper } from '@ids-utilities';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_INDUSTRY_OPTION = 'All Relevant Industries';

const UNDEFINED_INDUSTRY_OPTION = 'Undefined Industry';

@Component({
  selector: 'app-shared-benchmark',
  templateUrl: './shared-benchmark.component.html',
  styleUrls: ['./shared-benchmark.component.scss'],
})
export class SharedBenchmarkComponent extends BaseComponent implements AfterViewInit {
  @Input() isBordered = false;

  @Input() header = '';

  @Input() isLoading = false;

  _score: WritableSignal<number> = signal(0);

  get score() {
    return this._score();
  }

  @Input() set score(value: number) {
    this._score.set(Math.round(value * 100));
  }

  isScoreValid = computed(() => {
    return !Number.isNaN(this._score());
  });

  htmlDesc = computed((): SafeHtml => {
    if (!!this._description()) {
      return this.sanitizer.bypassSecurityTrustHtml(this._description().replace('{0}', this.score.toString()).replace('{1}', this.scoreColor()));
    }
    return this.sanitizer.bypassSecurityTrustHtml('');
  });

  _percentilesObj: WritableSignal<any> = signal({});

  percentilesArr = computed((): MenuItem[] => {
    let entries = Object.entries(this._percentilesObj() || {}).map(([key, value]: [string, any]) => {
      const label = this.util.getUppercaseFirstLetter(key.replace(/_/g, ' '));
      return {
        value: value,
        label: label === 'Null' ? UNDEFINED_INDUSTRY_OPTION : label,
        command: () => {
          this.selectedPercentile.set(label);
        },
      } as MenuItem;
    });
    entries = this.util.sortObjectArray(entries, 'label');
    const undefinedIndustry = entries.find((p) => p.label === UNDEFINED_INDUSTRY_OPTION);
    if (!!undefinedIndustry) {
      entries = [...entries.filter((p) => p.label !== UNDEFINED_INDUSTRY_OPTION), undefinedIndustry];
    }
    const allRelevantIndustries = entries.find((p) => p.label === DEFAULT_INDUSTRY_OPTION);
    if (!!allRelevantIndustries) {
      entries = [allRelevantIndustries, ...entries.filter((p) => p.label !== DEFAULT_INDUSTRY_OPTION)];
    }
    return entries;
  });

  @Input() set percentilesObj(value: any) {
    this._percentilesObj.set(value);
    setTimeout(() => {
      if (!this.breadcrumbConfig?.projectId) {
        this.selectedPercentile.set(DEFAULT_INDUSTRY_OPTION);
      } else {
        this.selectedPercentile.set(this.percentilesArr().find((p) => p.label !== DEFAULT_INDUSTRY_OPTION)?.['label'] || '');
      }
    });
  }

  selectedPercentile: WritableSignal<string> = signal('');

  selectedPercentileValue = computed(() => {
    const value = this.percentilesArr().find((p) => p.label === this.selectedPercentile())?.['value'];
    if (value !== null) {
      return Math.round(value * 100);
    }
    return null;
  });

  _description: WritableSignal<string> = signal(
    `Your current cybersecurity posture is rated at <span style="color: {1}">{0}%</span>.` +
      ` To enhance security measures, refer to the results below for detailed information on non-compliant devices` +
      ` and their corresponding recommended remediation steps`,
  );

  get description() {
    return this._description();
  }

  @Input() set description(value: string) {
    this._description.set(value);
  }

  DEFAULT_INDUSTRY_OPTION = DEFAULT_INDUSTRY_OPTION;

  UNDEFINED_INDUSTRY_OPTION = UNDEFINED_INDUSTRY_OPTION;

  projectName = '';

  @ViewChild('diagram') diagram!: ElementRef;

  chart: Chart | null = null;

  initChart$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private sanitizer: DomSanitizer) {
    super();
    effect(() => {
      this.selectedPercentile();
      setTimeout(() => {
        this.initChart$.next(null);
      }, 500);
    });
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initChart$.asObservable().subscribe(() => {
      this.initChart();
    });
  }

  initChart() {
    this.chart?.destroy();
    if (!!this.diagram?.nativeElement) {
      this.chart = new Chart(this.diagram.nativeElement, {
        data: {
          labels: Array.from({ length: 101 }, (_, index) => index),
          datasets: [
            {
              type: 'line',
              label: 'point',
              data: this.generateBellCurveData(50, 20, 0, 100, 100, 50, 0).filter((p) => p.x > (this.selectedPercentileValue() || 0)),
              fill: true,
              borderColor: this.getDarkerColor(this.percentileColor(), 0.75),
              backgroundColor: (context: any) => {
                const { ctx } = context.chart;
                const gradient = getGradientBackgroundColor(ctx, this.getDarkerColor(this.percentileColor(), 0.75));
                return gradient;
              },
              tension: 0.2,
            },
            {
              type: 'line',
              label: 'chart',
              data: this.generateBellCurveData(50, 20, 0, 100, 100, 50, 0),
              fill: true,
              borderColor: this.percentileColor(),
              backgroundColor: (context: any) => {
                const { ctx } = context.chart;
                const gradient = getGradientBackgroundColor(ctx, this.percentileColor());
                return gradient;
              },
              pointBorderWidth: 3,
              pointHoverBorderWidth: 3,
              pointBackgroundColor: '#ffffff',
              pointHoverRadius: (ctx) => {
                return ctx.parsed.x === this.selectedPercentileValue() ? 5 : 0;
              },
              pointRadius: (ctx) => {
                return ctx.parsed.x === this.selectedPercentileValue() ? 5 : 0;
              },
              tension: 0.2,
            },
          ],
        },
        options: {
          ...this.util.cloneDeepObject(CHART_OPTIONS),
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                title: (tooltip: any) => {
                  return tooltip?.[0]?.label === (this.selectedPercentileValue() || '').toString() ? 'Compliance' : '';
                },
                displayColors: false,
                label: (item) => {
                  return item.label === (this.selectedPercentileValue() || '').toString()
                    ? [`Score: ${this.score}%`, `Percentile: ${this.selectedPercentileValue()}%`]
                    : '';
                },
              },
            },
          },
        },
      });
      setTimeout(() => {
        if (!!this.chart) {
          this.chart.data = { ...this.chart?.data };
          this.chart.update();
        }
      });
    }
  }

  generateBellCurveData(
    mean: number,
    stdDev: number,
    xMin: number,
    xMax: number,
    numPoints: number,
    peakY: number,
    bottomY: number,
  ): { x: number; y: number }[] {
    const data: { x: number; y: number }[] = [];
    const step = (xMax - xMin) / numPoints;

    // Calculate the peak value of the normal distribution
    const peakNormValue = 1 / Math.sqrt(2 * Math.PI * Math.pow(stdDev, 2));

    for (let x = xMin; x <= xMax; x += step) {
      const exponent = -Math.pow(x - mean, 2) / (2 * Math.pow(stdDev, 2));
      const normY = (1 / Math.sqrt(2 * Math.PI * Math.pow(stdDev, 2))) * Math.exp(exponent);

      // Scale the normal distribution value to fit within [bottomY, peakY]
      const y = bottomY + (peakY - bottomY) * (normY / peakNormValue);

      data.push({ x, y });
    }
    return data;
  }

  getDarkerColor(hexColor: string, factor: number) {
    return NetworkMapHelper.getDarkerColor(hexColor, factor);
  }

  scoreColor = computed(() => {
    if (this.selectedPercentileValue() !== null) {
      return '#FFFFFF';
    } else {
      let c = '#FB4D58';
      if (this._score() <= 40) {
        c = '#FB4D58';
      } else if (this._score() > 40 && this._score() < 80) {
        c = '#FF9534';
      } else {
        c = '#00AD74';
      }
      return c;
    }
  });

  percentileColor = computed(() => {
    let c = '#FB4D58';
    const percentile = this.selectedPercentileValue() || 0;
    if (percentile <= 40) {
      c = '#FB4D58';
    } else if (percentile > 40 && percentile < 80) {
      c = '#FF9534';
    } else {
      c = '#00AD74';
    }
    return c;
  });
}
