import { Util } from '@microsec/utilities';
import { ChartOptions } from 'chart.js';

export const CHART_OPTIONS: ChartOptions = {
  animation: false,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        color: 'transparent',
      },
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: 55,
      ticks: {
        display: false,
      },
      grid: {
        color: 'transparent',
      },
    },
  },
};

export const getGradientBackgroundColor = (ctx: any, color: string) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  const rgb = Util.hexToRgb(color);
  gradient.addColorStop(0, color);
  gradient.addColorStop(0.5, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.5)`);
  gradient.addColorStop(1, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0)`);
  return gradient;
};
