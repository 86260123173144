<div class="grid">
  <div class="col-12" *ngIf="mode !== 'GROUP'">
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"></microsec-common-toolbar>
  </div>

  <div
    class="col-12"
    [ngClass]="{ 'lg:col-6': !!selectedThreat && mode !== 'DEVICE' && mode !== 'GROUP' }"
    id="tb"
    (click)="hideDetailsPanel($event)">
    <microsec-card [isNestedCard]="mode === 'DEVICE'" [isTableCard]="true">
      <microsec-common-table
        #dt
        [lazy]="mode !== 'GROUP'"
        (lazyLoadEvent)="getThreats(false, true, $any($event))"
        [totalRecords]="mode !== 'GROUP' ? totalRecords : values.length"
        [cols]="selectedCols"
        [values]="values"
        selectionMode="multiple"
        [(selection)]="selectedThreats"
        [(rowSingleSelection)]="selectedThreat"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [actionButtonsTemplate]="actionButtonsTemplate"
        [dataKey]="'threat_id'"
        [itemName]="mode ? ITEM_NAME_BY_MODE[mode] : 'threat'"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate"
        [noSortingCols]="['device_ids']">
      </microsec-common-table>
    </microsec-card>
  </div>
  <div class="col-12 lg:col-6" *ngIf="!!selectedThreat && mode !== 'DEVICE' && mode !== 'GROUP'">
    <div class="container-border">
      <app-threat-details [threatId]="selectedThreat.threat_id" (hidePanelEvent)="hideDetailsPanel()"></app-threat-details>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedThreats)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedThreats.length || (this.mode === 'DEVICE' && !!this.device.is_imported)"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-split-button
      buttonClass="p-button-primary"
      icon="fa fa-list-check"
      [mainModel]="statusButtonItems"
      [dropdownModel]="statusAllButtonItems"
      tooltip="Update Status"
      [disabledMain]="!selectedThreats.length || ($any(this.mode) === 'DEVICE' && !!this.device.is_imported)"
      [disabledDropdown]="!selectedThreats.length || ($any(this.mode) === 'DEVICE' && !!this.device.is_imported)"
      *ngIf="mode !== 'DEVICE' && !!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-split-button>
    <microsec-refresh-button (refreshEvent)="threatSrv.refresh$.next({ resetSelectedThreats: false })"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Date -->
    <ng-container *ngSwitchCase="col.field === 'created' || col.field === 'updated' ? col.field : ''">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- Category -->
    <ng-container *ngSwitchCase="'non_compliance_category'">
      {{ rowData[col.field] ? (rowData[col.field] | uppercase) : '-' }}
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <microsec-score-scale [inputNumber]="rowData.threat_score" [tooltipText]="'Threat Score'"></microsec-score-scale>
    </ng-container>
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <ng-container *ngIf="!!rowData.updatableStatus; else elseTemplate">
        <p-dropdown
          (click)="$event.stopPropagation()"
          class="status-dropdown bordered-dropdown uppercase"
          [ngClass]="{
            'red-dropdown': rowData.updatableStatus.toLowerCase() === 'open' && rowData.updatableIsTruePositive === null,
            'orange-dropdown': rowData.updatableStatus.toLowerCase() === 'fixing' && rowData.updatableIsTruePositive === true,
            'green-dropdown': rowData.updatableStatus.toLowerCase() === 'closed' && rowData.updatableIsTruePositive === true,
            'grey-dropdown': rowData.updatableStatus.toLowerCase() === 'closed' && rowData.updatableIsTruePositive === false
          }"
          [options]="threatStatusOptions"
          optionLabel="label"
          appendTo="body"
          (onChange)="
            (rowData.status !== $event.value?.dataValue || rowData.is_true_positive !== $event.value?.isTruePositive) &&
              openUpdateStatusConfirmation([rowData], $event.value)
          "
          [disabled]="!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] || !!rowData.is_imported">
          <ng-template pTemplate="selectedItem">
            <div [pTooltip]="rowData | constant: 'threat-status-tooltip'" tooltipPosition="top" appendTo="body">
              @if (rowData.updatableStatus.toLowerCase() === 'closed') {
                @if (!!rowData.updatableIsTruePositive) {
                  <span class="fa fa-circle-check mr-1"></span>
                } @else {
                  <span class="fa fa-bell-slash mr-1"></span>
                }
              }
              <span>{{ rowData | constant: 'threat-status' }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </ng-container>
    </ng-container>
    <!-- Threat Type -->
    <ng-container *ngSwitchCase="'threat_type'">
      {{ rowData.threat_type ? (rowData.threat_type | constant: 'threat-type') : '-' }}
    </ng-container>
    <!-- Attack Type -->
    <ng-container *ngSwitchCase="'attack_type'">
      {{ rowData.attack_type ? (rowData.attack_type | constant: 'threat-attack-type') : '-' }}
    </ng-container>
    <ng-container *ngSwitchCase="'device_ids'">
      @if (!!rowData.device_ids?.length) {
        <ng-container *ngFor="let threatDevice of rowData.device_ids; let lastThreatDevice = last">
          {{ threatDevice.label || 'Device' + threatDevice.device_id }}<span *ngIf="!lastThreatDevice">,&nbsp;</span>
        </ng-container>
      } @else {
        <ng-container [ngTemplateOutlet]="elseTemplate"></ng-container>
      }
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-rowData="rowData" #actionButtonsTemplate>
  <div class="flex align-items-center gap-3">
    <span
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"
      class="icon-button"
      pTooltip="Change Status"
      tooltipPosition="bottom"
      appendTo="body"
      (click)="onClickEditStatusButton($event, rowData)">
      <i class="fa fa-list-check"></i>
    </span>
    <span
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"
      class="icon-button"
      pTooltip="Delete"
      tooltipPosition="bottom"
      appendTo="body"
      (click)="openDeleteConfirmation([rowData])">
      <i class="fa fa-trash"></i>
    </span>
  </div>
</ng-template>

<ng-template #elseTemplate> - </ng-template>

<p-sidebar
  [(visible)]="displayThreatDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedThreat = null">
  @if (!!selectedThreat && !!displayThreatDetails) {
    <app-threat-details [threatId]="selectedThreat.threat_id" (hidePanelEvent)="selectedThreat = null"></app-threat-details>
  }
</p-sidebar>

<p-overlayPanel #rowStatusEditPanel styleClass="no-padding no-border">
  <p-menu styleClass="microsec-button-context-menu" [model]="rowStatusButtonItems"></p-menu>
</p-overlayPanel>
