<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="grid">
    <div class="col-12">
      <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" [rightTemplate]="rightTemplate" />
      <ng-template #rightTemplate>
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center gap-2">
            @if (!!isRefreshIntervalEnabled && !!refreshIntervalTimer) {
              <div class="mt-2 flex justify-content-end">
                <label class="text-white">Next Refresh in {{ refreshIntervalTimer | date: 'HH:mm:ss' }}</label>
              </div>
            }
            <microsec-operation-button
              [isThreeEvent]="true"
              operation="Refresh"
              buttonClass="p-button-primary"
              icon="fa fa-refresh"
              [allOperationLabel]="(!!isRefreshIntervalEnabled ? 'Disable' : 'Enable') + ' Auto Refresh'"
              selectedOperationLabel="Edit Refresh Interval"
              (clickEvent)="getData()"
              (allOperationEvent)="setRefreshInterval(true, !isRefreshIntervalEnabled, false)"
              (selectedOperationEvent)="openRefreshIntervalForm()" />
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-12">
      <div class="grid">
        <div
          id="overall-threat-score"
          class="col-12"
          [ngClass]="{ 'lg:col-6 xl:col-3': !!breadcrumbConfig?.projectId, 'lg:col-6 xl:col-4': !breadcrumbConfig?.projectId }">
          <microsec-card [isBordered]="true" [isFull]="true">
            <label class="font-bold">Overall Threat Score</label>
            <div class="grid align-items-center h-full">
              <div class="col-12 text-center mt-2">
                <microsec-common-gauge
                  [color]="
                    (summary?.threat_score | threatScorePipe) < 4 ? '#00ad74' : (summary?.threat_score | threatScorePipe) < 8 ? '#ff9534' : '#fb4d58'
                  "
                  [value]="summary?.threat_score | threatScorePipe"
                  append="/10"
                  [label]="(summary?.threat_score | threatScorePipe) < 4 ? 'Low' : (summary?.threat_score | threatScorePipe) < 8 ? 'Medium' : 'High'"
                  [labelClass]="
                    (summary?.threat_score | threatScorePipe) < 4
                      ? 'text-green'
                      : (summary?.threat_score | threatScorePipe) < 8
                        ? 'text-orange'
                        : 'text-red'
                  " />
              </div>
            </div>
          </microsec-card>
        </div>
        <div
          id="threat-overview"
          class="col-12"
          [ngClass]="{ 'lg:col-6 xl:col-3': !!breadcrumbConfig?.projectId, 'lg:col-6 xl:col-4': !breadcrumbConfig?.projectId }">
          <microsec-card [isBordered]="true" [isFull]="true">
            <div class="grid">
              <label class="col-12 font-bold">Threat Overview</label>
            </div>
            <div class="flex flex-column w-full h-full justify-content-center">
              <div class="grid w-full">
                <div class="col-12 text-center">
                  <label class="text-6xl font-bold">
                    <span>{{ summary?.total || 0 }}</span>
                    <span class="text-sm"> {{ !!isActiveThreats ? 'Active' : 'All' }} Threats</span>
                  </label>
                </div>
                <div class="col-12 flex justify-content-center">
                  <div class="grid grid-nogutter max-w-full" style="width: 40rem">
                    @for (scoreBlock of scoreBlocks; track $index) {
                      <div
                        class="border-gray-800 border-top-1 py-3"
                        [ngClass]="{ 'col-4': $index < 3, 'col-6 border-bottom-1': $index >= 3, 'border-right-1': [0, 1, 3].includes($index) }">
                        <div class="flex flex-column align-items-center gap-1">
                          <label
                            class="text-lg font-bold"
                            [ngClass]="{
                              'text-red': scoreBlock.label === 'High' || scoreBlock.label === 'Open',
                              'text-orange': scoreBlock.label === 'Medium' || scoreBlock.label === 'Fixing',
                              'text-green': scoreBlock.label === 'Low'
                            }">
                            {{ summary?.overview?.[$any(scoreBlock.label?.toLowerCase())] || 0 }}
                          </label>
                          <label class="text-lg">
                            {{ scoreBlock.label }}
                          </label>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </microsec-card>
        </div>
        @if (!breadcrumbConfig?.projectId) {
          <!-- ORGANIZATION LEVEL -->
          @if (!!threatByScoreChartConfig) {
            <div id="threat-scores-by-project" class="col-12 lg:col-6 xl:col-4">
              <microsec-card class="h-full" [isChartCard]="true" [isBordered]="true" [isFull]="true">
                @if (!!threatByScoreChartConfig.data?.labels?.length) {
                  <microsec-scrollable-bar-chart
                    [title]="DIAGRAMS.THREAT_SCORES_BY_PROJECT.LABEL"
                    [chartData]="$any(threatByScoreChartConfig.data)"
                    [scrollChartHeight]="threatScoresByProjectChartHeight"
                    [step]="1"
                    [maxStep]="10" />
                } @else {
                  <div class="flex flex-column w-full h-full">
                    <div class="text-data font-bold">{{ DIAGRAMS.THREAT_SCORES_BY_PROJECT.LABEL }}</div>
                  </div>
                  <div class="w-full h-full justify-content-center">
                    <div class="text-data text-center">No data found</div>
                  </div>
                }
              </microsec-card>
            </div>
          }
          <div class="col-12">
            <app-dashboard-timeline
              [options]="timelineViewOptions"
              [summary]="summary"
              dataField="threats_by_timeline"
              [filterRange]="filterRange"
              [changeLabel]="getTimelineChartLabel" />
          </div>
        } @else {
          <!-- PROJECT LEVEL -->
          <div class="col-12 lg:col-12 xl:col-6">
            <microsec-card [isBordered]="true" [isFull]="true">
              <div class="grid">
                <label class="col-12 font-bold">Threat Timeline</label>
                <div class="col-12">
                  <div style="height: 20rem" [ngClass]="{ hidden: !summary?.total }">
                    <canvas #timelineDiagram></canvas>
                  </div>
                  <div class="flex" style="height: '20rem'" *ngIf="!summary?.total">
                    <label class="m-auto">No data found</label>
                  </div>
                  <div class="flex flex-row flex-wrap justify-content-center ml-2">
                    <div class="py-2" *ngFor="let option of timelineChartOptions">
                      <p-radioButton labelStyleClass="mr-6" [(ngModel)]="selectedTimelineChart" [value]="option.value" [label]="option.label" />
                    </div>
                  </div>
                </div>
              </div>
            </microsec-card>
          </div>
        }
      </div>
    </div>
    <div class="col-12">
      <microsec-common-overview #dashboardThreatsComponent [charts]="drawnCharts" [getLegendLabel]="getLegendLabel" [isBordered]="true" />
    </div>
    <div class="col-12">
      <microsec-card [isBordered]="true">
        <div class="grid">
          <label class="col-12 font-bold">Critical Devices</label>
          <div class="col-12">
            <microsec-card [isTableCard]="true">
              <microsec-common-table
                #dtDevice
                [lazy]="true"
                (lazyLoadEvent)="getDevices(true, $event)"
                [totalRecords]="deviceTotalRecords"
                [cols]="deviceCols"
                [values]="devices"
                [(selection)]="selectedDevice"
                [loading]="isLoadingDevice"
                [cellTemplate]="deviceCellTemplate"
                [dataKey]="'id'"
                [scrollHeight]="'40rem'"
                [noSortingCols]="['id', 'label', 'type', 'interfaces', 'threat_score']"
                [showContainerHeader]="false"
            /></microsec-card>
          </div>
        </div>
      </microsec-card>
    </div>
    <div class="col-12">
      <microsec-card [isBordered]="true">
        <div class="grid">
          <label class="col-12 font-bold">Recent Threats</label>
          <div class="col-12">
            <microsec-card [isTableCard]="true">
              <microsec-common-table
                #dtThreat
                [lazy]="true"
                (lazyLoadEvent)="getThreats(true, $event)"
                [totalRecords]="threatTotalRecords"
                [cols]="threatCols"
                [values]="threats"
                [(selection)]="selectedThreat"
                [loading]="isLoadingThreat"
                [cellTemplate]="threatCellTemplate"
                [dataKey]="'threat_id'"
                [scrollHeight]="'40rem'"
                [noSortingCols]="['created', 'threat_id', 'threat_type', 'attack_type', 'threat_score', 'status']"
                [showContainerHeader]="false" />
            </microsec-card>
          </div>
        </div>
      </microsec-card>
    </div>
  </div>
</microsec-loading-overlay>

<ng-template #timeRangeItemTemplate let-item="item">
  <div class="-mx-3 -my-2 px-3 py-2" (click)="item.value === 'custom' && openCustomTimeRangeFormComponent()">
    <label class="text-data">
      {{ item.label }}
    </label>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #deviceCellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Type -->
    <ng-container *ngSwitchCase="'type'">
      <microsec-truncate-text>
        {{ (rowData[col.field] | constant: 'detected-device-type') || '-' }}
      </microsec-truncate-text>
    </ng-container>
    <!-- Interfaces -->
    <ng-container *ngSwitchCase="'interfaces'">
      {{ rowData | detectedDeviceInterfacesPipe }}
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <span [ngClass]="{ 'text-red': !!rowData[col.field] }">
        {{ (rowData[col.field] | number: '1.0-0') || '-' }}
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #threatCellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Timestamp -->
    <ng-container *ngSwitchCase="'created'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <microsec-score-scale [inputNumber]="rowData.threat_score" [tooltipText]="'Threat Score'" />
    </ng-container>
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <div
        class="uppercase max-w-max bordered-status"
        [ngClass]="{
          red: rowData.status.toLowerCase() === 'open',
          orange: rowData.status.toLowerCase() === 'fixing'
        }">
        {{ rowData['status'] || '-' }}
      </div>
    </ng-container>
    <!-- Zones -->
    <ng-container *ngSwitchCase="'zones'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Attack Type -->
    <ng-container *ngSwitchCase="'attack_type'">
      {{ rowData.attack_type ? (rowData.attack_type | constant: 'threat-attack-type') : '-' }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<p-sidebar
  [(visible)]="displayDeviceDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedDevice = null">
  @if (!!selectedDevice && !!displayDeviceDetails) {
    <app-shared-detected-device-details [deviceId]="selectedDevice.id" (hidePanelEvent)="selectedDevice = null" />
  }
</p-sidebar>

<p-sidebar
  [(visible)]="displayThreatDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedThreat = null">
  @if (!!selectedThreat && !!displayThreatDetails) {
    <app-threat-details [threatId]="selectedThreat.threat_id" (hidePanelEvent)="selectedThreat = null" />
  }
</p-sidebar>
