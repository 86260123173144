export const ONBOARDING_PROGRESS = 'onboardingProgress';

export const INDUSTRIES = {
  BUILDING_AUTOMATION_SYSTEMS: 'Building Automation Systems',
  MANUFACTURING: 'Manufacturing',
  AUTOMOTIVE: 'Automotive',
  CHEMICAL: 'Chemical',
  ELECTRIC_UTILITIES: 'Electric Utilities',
  FOOD_BEVERAGE: 'Food & Beverage',
  OIL_GAS: 'Oil & Gas',
  WATER_SYSTEMS: 'Water Systems',
  MINING: 'Mining',
  PHARMACEUTICAL_BIOTECHNOLOGY: 'Pharmaceutical & Biotechnology',
  TRANSPORTATION: 'Transportation',
  HEALTHCARE: 'Healthcare',
  PUBLIC_SECTOR: 'Public Sector',
  MARITIME: 'Maritime',
};

export const SECURITY_OBJECTIVE_VALUES = {
  DETECTION: 'DETECTION',
  PROTECTION: 'PROTECTION',
  EVALUATION: 'EVALUATION',
};

export const SECURITY_OBJECTIVES = {
  DETECTION: 'Detecting and alerting network-based threats and anomalies',
  PROTECTION: 'Protecting specific OT and IoT devices from targeted attacks',
  EVALUATION: 'System Evaluation (Assessments)',
};

export const SECURITY_OBJECTIVE_OPTIONS = [
  { value: SECURITY_OBJECTIVE_VALUES.DETECTION, label: SECURITY_OBJECTIVES.DETECTION },
  { value: SECURITY_OBJECTIVE_VALUES.PROTECTION, label: SECURITY_OBJECTIVES.PROTECTION },
  { value: SECURITY_OBJECTIVE_VALUES.EVALUATION, label: SECURITY_OBJECTIVES.EVALUATION },
];
