import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import {
  CONNECTION_TYPES,
  CONNECTION_TYPE_LABELS,
  MICROAGENT_AGENT_VERSION_OPTIONS,
  MICROAGENT_ARCHITECTURE_OPTIONS,
  MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS,
  MICROAGENT_FEATURES,
  MICROAGENT_FEATURE_OPTIONS,
  MICROAGENT_FILES,
  MICROAGENT_OPERATING_SYSTEMS,
  MICROAGENT_OPERATING_SYSTEM_OPTIONS,
  MQTT_AGENT_BROKERS,
  MQTT_AGENT_BROKER_OPTIONS,
  MQTT_AGENT_BUILTIN_DEFAULT_VALUES,
  STATUSES,
} from '@ids-constants';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DOCUMENTATION_URL, DOMAIN_TOKEN, SHORT_TEXT_MAX_LENGTH, VALIDATOR_TYPE } from '@microsec/constants';
import moment from 'moment';
import { TreeSelect } from 'primeng/treeselect';
import { Observable, finalize } from 'rxjs';
import { ConnectionService } from '@ids-services';
import { Chips } from 'primeng/chips';
import { TopicsFieldComponent } from './topics-field/topics-field.component';
import { BaseComponent } from '@ids-components';
import { AccessTokenFormComponent } from '@ids-products';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  INTERFACE: 'interface',
  NAME: 'name',
  DESCRIPTION: 'description',
  STATUS: 'status',
  ENABLED: 'enabled',
  TYPE: 'type',
  PROTOCOLS: 'protocols',
  MACS_TO_IGNORE: 'macs_to_ignore',
  IPS_TO_IGNORE: 'ips_to_ignore',
  PCAP_FILE_PATH: 'file_path',
  PCAP_FILE_NAME: 'origin_filename',
  RELOAD_PCAP: 'reload_pcap',
  ACCESS_TOKEN: 'access_token',
  TOPIC: 'topic',
  TSHARK_COMPATIBILITY: 'tshark_compatibility',
  PREPROCESSING_LAMBDA: 'preprocessing_lambda',
  PROCESSING_LAMBDA: 'processing_lambda',
  // INTERFACE PARAMS
  INTERFACE_IDENTIFIER: 'identifier',
  INTERFACE_USE_PACKET_PROCESSOR: 'use_packet_processor',
  INTERFACE_BROKER_HOSTNAME: 'broker_hostname',
  INTERFACE_BROKER_PORT: 'broker_port',
  INTERFACE_IS_TLS: 'is_tls',
  INTERFACE_CLIENT_ID: 'client_id',
  INTERFACE_TLS_CERTIFICATE: 'tls_certificate',
  INTERFACE_TLS_CERTIFICATE_ORIGIN_FILENAME: 'tls_certificate_origin_filename',
  INTERFACE_IS_ANONYMOUS: 'is_anonymous',
  INTERFACE_BROKER_USERNAME: 'broker_username',
  INTERFACE_EXTERNAL_BROKER_PASSWORD: 'external_broker_password',
  INTERFACE_BROKER_CLIENT_CERT: 'broker_client_cert',
  INTERFACE_BROKER_CLIENT_CERT_ORIGIN_FILENAME: 'broker_client_cert_origin_filename',
  INTERFACE_BROKER_CLIENT_KEY: 'broker_client_key',
  INTERFACE_BROKER_CLIENT_KEY_ORIGIN_FILENAME: 'broker_client_key_origin_filename',
  INTERFACE_TOPICS: 'topics',
  // HELPER PARAMS
  SETUP_STEP: 'setupStep',
  FINALIZE_STEP: 'finalizeStep',
  PCAP_FILE: 'pcapFile',
  INTERFACE_TLS_CERTIFICATE_FILE: 'tlsCertificateFile',
  INTERFACE_BROKER_CLIENT_CERT_FILE: 'brokerClientCertFile',
  INTERFACE_BROKER_CLIENT_KEY_FILE: 'brokerClientKeyFile',
  IS_USER_AUTH: 'isUserAuth',
  IS_CERT_AUTH: 'isCertAuth',
  MQTT_BROKER: 'mqttBroker',
  // QUESTIONNAIRE MQTT_AGENT PARAMS
  OS: 'os',
  ARCHITECTURE: 'architecture',
  FEATURES: 'features',
  CRYPTOGRAPHIC: 'cryptographic',
  VERSION: 'version',
};

const SERVER_ERROR_REPLACE_KEYS = {
  [FORM_PARAMS.INTERFACE_TLS_CERTIFICATE]: FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE,
  [FORM_PARAMS.PCAP_FILE_PATH]: FORM_PARAMS.PCAP_FILE,
};

const IP_REGEX =
  '^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])((/([0-9]|[1-2][0-9]|3[0-2]))?)|((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))))((/([8-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?)$';

const MAC_REGEX = '^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$';

const POLLING_FREQUENCY_UNIT_OPTIONS = [
  { label: 'Seconds', value: 's' },
  { label: 'Minutes', value: 'm' },
  { label: 'Hours', value: 'h' },
];

const SETUP_ITEMS = {
  NETWORK_CONFIG: 'Network Configuration',
  ADVANCED_SETTINGS: 'Advanced Settings',
  PCAP_FILE: 'Upload PCAP File',
  OS_ARCHITECTURE: 'Operating System and Architecture',
  FEATURES_CONFIG_VERSION: 'Features, Configuration and Version',
  USER_AUTH_CONFIG: 'User Authentication Configuration',
  CERT_AUTH_CONFIG: 'Certificate Authentication Configuration',
  DOWNLOAD_AGENT: 'Download Agent',
  TOPIC_CONFIG: 'Topic Configuration',
};

const NODE_TITLES: {
  [key: string]: {
    title: string;
    subtitle: string;
  };
} = {
  ['finalize']: {
    title: 'Finalize Network Information',
    subtitle: 'Provide the following network information to complete network setup.',
  },
  ['config']: {
    title: 'Installation & Configuration',
    subtitle: 'Installation and configuration details to set up data forwarding for MicroIDS Node.',
  },
};

const MQTT_TITLES: {
  [key: string]: {
    title: string;
    subtitle: string;
  };
} = {
  ['config']: {
    title: 'Setup MicroAgent Network',
    subtitle: 'Provide the following information to setup MicroAgent network.',
  },
  ['download']: {
    title: 'Download MicroAgent',
    subtitle: 'Download and install MicroAgent on devices to be managed by MicroIDS.',
  },
  ['guide']: {
    title: 'Installation & Configuration Guide',
    subtitle: 'Installation guide and configuration details to assist with your MicroAgent installation.',
  },
};

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent extends BaseComponent implements OnInit, OnDestroy {
  hideAdvanceSettings = true;

  agentDownloaded = false;

  @Input() type: string = '';

  @Input() isContinuousScan = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('setupTemplate') setupTemplate!: TemplateRef<any>;

  @ViewChild('finalizeTemplate') finalizeTemplate!: TemplateRef<any>;

  @ViewChild('protocolsTreeSelect') protocolsTreeSelect!: TreeSelect;

  @ViewChild('topicsFieldComponent') topicsFieldComponent!: TopicsFieldComponent;

  @ViewChildren('chips') chipComponents!: QueryList<Chips>;

  @Input() step: number = 1;

  @Input() connection: any = null;

  @Output() updateStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() setLocalStorageEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() availableConnection: any[] = [];

  @Input() connectionProtocols: any = {};

  @Input() protocols: any = {};

  protocolOptions: any[] = [];

  topics: any[] = [];

  allProtocolsSelected = false;

  unitOptions = POLLING_FREQUENCY_UNIT_OPTIONS;

  FORM_PARAMS = FORM_PARAMS;

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  STATUSES = STATUSES;

  CONNECTION_TYPES = CONNECTION_TYPES;

  SETUP_ITEMS = SETUP_ITEMS;

  IP_REGEX = IP_REGEX;

  MAC_REGEX = MAC_REGEX;

  MICROAGENT_OPERATING_SYSTEMS = MICROAGENT_OPERATING_SYSTEMS;

  MICROAGENT_FEATURES = MICROAGENT_FEATURES;

  operatingSystemsOptions = MICROAGENT_OPERATING_SYSTEM_OPTIONS;

  architectureOptions = MICROAGENT_ARCHITECTURE_OPTIONS;

  featureOptions: any[] = [];

  cryptographicOptions = MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS;

  versionOptions = MICROAGENT_AGENT_VERSION_OPTIONS;

  MQTT_AGENT_BROKERS = MQTT_AGENT_BROKERS;

  MQTT_AGENT_BROKER_OPTIONS = MQTT_AGENT_BROKER_OPTIONS;

  MQTT_AGENT_BUILTIN_DEFAULT_VALUES = MQTT_AGENT_BUILTIN_DEFAULT_VALUES;

  setupItems: any[] = [];

  serverErrors: any = null;

  mqttAgentSetupStep: string = '';

  nodeSetupStep: string = '';

  isCreate = true;

  mqttBrokerAddress = '';

  mqttBrokerPassword = '';

  constructor(private connectionSrv: ConnectionService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.isCreate = !this.connection;
    this.topics = this.connection?.[FORM_PARAMS.INTERFACE_TOPICS] || this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_TOPICS] || [];
    if (!this.isCreate && (this.type === CONNECTION_TYPES.MQTT_AGENT || this.type === CONNECTION_TYPES.NODE)) {
      this.updateStepEvent.emit({ step: 1 });
    }
    this.initForm();
    this.setSetupItems(!!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_IS_TLS]);
  }

  setSetupItems(isTls = false) {
    const items: any[] = [
      ...(this.type === CONNECTION_TYPES.PHYSICAL || this.type === CONNECTION_TYPES.NODE || this.type === CONNECTION_TYPES.MQTT
        ? [{ name: SETUP_ITEMS.NETWORK_CONFIG }]
        : []),
      ...(this.type === CONNECTION_TYPES.PCAP ? [{ name: SETUP_ITEMS.PCAP_FILE }] : []),
      ...(this.type === CONNECTION_TYPES.PHYSICAL || this.type === CONNECTION_TYPES.PCAP
        ? [{ name: SETUP_ITEMS.ADVANCED_SETTINGS, hideLabel: true, description: 'Only edit these if you are familiar with them.' }]
        : []),
      ...(this.type === CONNECTION_TYPES.MQTT
        ? [
            { name: SETUP_ITEMS.USER_AUTH_CONFIG, hideLabel: true },
            ...(!!isTls ? [{ name: SETUP_ITEMS.CERT_AUTH_CONFIG, hideLabel: true }] : []),
            { name: SETUP_ITEMS.TOPIC_CONFIG },
          ]
        : []),
    ];
    this.setupItems = items;
  }

  initForm() {
    this.setProtocolOptions(this.type);
    this.setMqttBrokerValues();
    const protocols = this.util.flattenObjectArray((this.protocolsTreeSelect?.options as any[]) || this.protocolOptions || [], 'children');
    const selectedProtocols = !!this.connection ? protocols.filter((p) => (this.connection[FORM_PARAMS.PROTOCOLS] || []).includes(p.key)) : protocols;
    this.allProtocolsSelected = selectedProtocols?.length === protocols?.length;

    const mqttBroker = !!this.connection?.[FORM_PARAMS.INTERFACE]
      ? this.connection[FORM_PARAMS.NAME] === MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.NAME] &&
        this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_HOSTNAME] ===
          MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_BROKER_HOSTNAME]
        ? MQTT_AGENT_BROKERS.BUILT_IN
        : MQTT_AGENT_BROKERS.EXTERNAL
      : MQTT_AGENT_BROKERS.BUILT_IN;

    const generalFields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        required: true,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.organizationId,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        required: true,
        hidden: true,
        defaultValue: this.breadcrumbConfig?.projectId,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        required: true,
        hidden: true,
        disabled: this.type === CONNECTION_TYPES.MQTT_AGENT && mqttBroker === MQTT_AGENT_BROKERS.BUILT_IN,
        defaultValue:
          this.connection?.[FORM_PARAMS.NAME] ||
          (this.type === CONNECTION_TYPES.MQTT_AGENT && mqttBroker === MQTT_AGENT_BROKERS.BUILT_IN
            ? MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.NAME]
            : `${CONNECTION_TYPE_LABELS[this.type]} (${moment().format('YYYYMMDD_HHmmss')})`),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        hidden: true,
        disabled: this.type === CONNECTION_TYPES.MQTT_AGENT && mqttBroker === MQTT_AGENT_BROKERS.BUILT_IN,
        defaultValue:
          this.connection?.[FORM_PARAMS.DESCRIPTION] ||
          (this.type === CONNECTION_TYPES.MQTT_AGENT && mqttBroker === MQTT_AGENT_BROKERS.BUILT_IN
            ? MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.DESCRIPTION]
            : ''),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        hidden: true,
        defaultValue: this.connection ? !!this.connection[FORM_PARAMS.ENABLED] : true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        required: true,
        hidden: true,
        defaultValue: this.type,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATUS,
        defaultValue: !!this.connection ? this.connection[FORM_PARAMS.STATUS] : null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SETUP_STEP,
        hasNoLabel: true,
        field: 'custom',
        customField: this.setupTemplate,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FINALIZE_STEP,
        hasNoLabel: true,
        field: 'custom',
        customField: this.finalizeTemplate,
        hidden: true,
      } as FormItem),
    ];
    const fields: FormItem[] = [
      ...generalFields,
      /************************* PHYSICAL *************************/
      ...(this.type === CONNECTION_TYPES.PHYSICAL
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_IDENTIFIER,
              required: true,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_IDENTIFIER] || null,
            } as FormItem),
          ]
        : []),
      /************************* PCAP *************************/
      ...(this.type === CONNECTION_TYPES.PCAP
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.PCAP_FILE,
              required: true,
              hidden: true,
              defaultValue:
                !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.PCAP_FILE_NAME] &&
                this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.TYPE] === CONNECTION_TYPES.PCAP
                  ? { name: this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.PCAP_FILE_NAME] }
                  : null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.PCAP_FILE_PATH,
              hidden: true,
            } as FormItem),
          ]
        : []),
      /************************* MQTT *************************/
      ...(this.type === CONNECTION_TYPES.MQTT
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_TOPICS,
              hidden: true,
              defaultValue: [],
            } as FormItem),
          ]
        : []),
      /************************* MQTT_AGENT *************************/
      ...(this.type === CONNECTION_TYPES.MQTT_AGENT
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.MQTT_BROKER,
              required: true,
              hidden: true,
              defaultValue: mqttBroker,
            } as FormItem),
          ]
        : []),
      /************************* PHYSICAL || PCAP *************************/
      ...(this.type === CONNECTION_TYPES.PHYSICAL || this.type === CONNECTION_TYPES.PCAP
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.PROTOCOLS,
              required: true,
              hidden: true,
              defaultValue: selectedProtocols,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.MACS_TO_IGNORE,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.MACS_TO_IGNORE] || [],
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.IPS_TO_IGNORE,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.IPS_TO_IGNORE] || [],
            } as FormItem),
          ]
        : []),
      /*************************  MQTT || MQTT_AGENT *************************/
      ...(this.type === CONNECTION_TYPES.MQTT || this.type === CONNECTION_TYPES.MQTT_AGENT
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_IS_TLS,
              hidden: true,
              defaultValue: !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_IS_TLS] || false,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE,
              hidden: true,
              defaultValue: !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_ORIGIN_FILENAME]
                ? { name: this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_ORIGIN_FILENAME] }
                : null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_TLS_CERTIFICATE,
              hidden: true,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_HOSTNAME,
              required: this.type === CONNECTION_TYPES.MQTT || mqttBroker === MQTT_AGENT_BROKERS.EXTERNAL,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_HOSTNAME] || null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_PORT,
              required: this.type === CONNECTION_TYPES.MQTT || mqttBroker === MQTT_AGENT_BROKERS.EXTERNAL,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_PORT] || null,
            } as FormItem),
          ]
        : []),
      /************************* MQTT || MQTT_AGENT *************************/
      ...(this.type === CONNECTION_TYPES.MQTT || this.type === CONNECTION_TYPES.MQTT_AGENT
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_CLIENT_ID,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_CLIENT_ID] || '',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.IS_USER_AUTH,
              hidden: true,
              defaultValue: !!this.connection?.[FORM_PARAMS.INTERFACE]
                ? !this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_IS_ANONYMOUS] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_USERNAME]
                : false,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_USERNAME,
              required: !!this.connection?.[FORM_PARAMS.INTERFACE]
                ? !this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_IS_ANONYMOUS] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_USERNAME]
                : false,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_USERNAME] || '',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD,
              hidden: true,
              defaultValue: this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD] || '',
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.IS_CERT_AUTH,
              hidden: true,
              defaultValue: !!this.connection?.[FORM_PARAMS.INTERFACE]
                ? !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_IS_TLS] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY]
                : false,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE,
              required: !!this.connection?.[FORM_PARAMS.INTERFACE]
                ? !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_IS_TLS] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY]
                : false,
              hidden: true,
              defaultValue:
                !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT] &&
                !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_ORIGIN_FILENAME]
                  ? { name: this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_ORIGIN_FILENAME] }
                  : null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT,
              hidden: true,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE,
              required: !!this.connection?.[FORM_PARAMS.INTERFACE]
                ? !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_IS_TLS] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT] &&
                  !!this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY]
                : false,
              hidden: true,
              defaultValue:
                !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY] &&
                !!this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_ORIGIN_FILENAME]
                  ? { name: this.connection[FORM_PARAMS.INTERFACE][FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_ORIGIN_FILENAME] }
                  : null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY,
              hidden: true,
            } as FormItem),
          ]
        : []),
      /************************* QUESTIONNAIRE MQTT_AGENT PARAMS *************************/
      ...(this.type === CONNECTION_TYPES.MQTT_AGENT
        ? [
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.OS,
              hidden: true,
              defaultValue: null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.ARCHITECTURE,
              hidden: true,
              defaultValue: null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.FEATURES,
              hidden: true,
              defaultValue: null,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.CRYPTOGRAPHIC,
              disabled: true,
              hidden: true,
              defaultValue: MICROAGENT_CRYPTOGRAPHIC_ENGINE_OPTIONS[0].value,
            } as FormItem),
            Object.assign(new FormItem(), {
              name: FORM_PARAMS.VERSION,
              hidden: true,
              defaultValue: MICROAGENT_AGENT_VERSION_OPTIONS[0].value,
            } as FormItem),
          ]
        : []),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.OS, (value: string) => {
        this.featureOptions = MICROAGENT_FEATURE_OPTIONS.map((option) => ({
          ...option,
          disabled: value === MICROAGENT_OPERATING_SYSTEMS.LINUX_OPENWRT && option.value !== MICROAGENT_FEATURES.PROTECTION,
        }));
        const enabledOptions = this.featureOptions.filter((option) => !option.disabled);
        this.form.setControlValue(
          FORM_PARAMS.FEATURES,
          value === MICROAGENT_OPERATING_SYSTEMS.LINUX_DEBIAN ? MICROAGENT_FEATURES.NIDS_HIDS : enabledOptions[0]?.value || null,
        );
      });
      this.form?.setChangeEvent(FORM_PARAMS.MQTT_BROKER, (value: string) => {
        this.form.setControlValidators(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME, value === MQTT_AGENT_BROKERS.EXTERNAL ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form.setControlValidators(FORM_PARAMS.INTERFACE_BROKER_PORT, value === MQTT_AGENT_BROKERS.EXTERNAL ? [VALIDATOR_TYPE.REQUIRED] : []);
        if (value === MQTT_AGENT_BROKERS.BUILT_IN) {
          this.form.getControl(FORM_PARAMS.NAME).disable();
          this.form.getControl(FORM_PARAMS.NAME).disable();
          this.form.setControlValue(FORM_PARAMS.NAME, MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.NAME]);
          this.form.setControlValue(FORM_PARAMS.DESCRIPTION, MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.DESCRIPTION]);
        } else {
          this.form.getControl(FORM_PARAMS.NAME).enable();
          this.form.getControl(FORM_PARAMS.NAME).enable();
          this.form.setControlValue(FORM_PARAMS.NAME, `${CONNECTION_TYPE_LABELS[this.type]} (${moment().format('YYYYMMDD_HHmmss')})`);
          this.form.setControlValue(FORM_PARAMS.DESCRIPTION, '');
        }
        this.onIsUserAuthChange(value === MQTT_AGENT_BROKERS.EXTERNAL && !!this.form.getControlValue(FORM_PARAMS.IS_USER_AUTH));
        this.onIsCertAuthChange(
          value === MQTT_AGENT_BROKERS.EXTERNAL &&
            !!this.form.getControlValue(FORM_PARAMS.INTERFACE_IS_TLS) &&
            !!this.form.getControlValue(FORM_PARAMS.IS_CERT_AUTH),
        );
      });
      this.form?.setChangeEvent(FORM_PARAMS.INTERFACE_IS_TLS, (value: boolean) => {
        if (this.type !== CONNECTION_TYPES.MQTT_AGENT) {
          this.setSetupItems(value);
        }
        if (!value) {
          this.removeError(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE);
        }
        this.onIsCertAuthChange(value && !!this.form.getControlValue(FORM_PARAMS.IS_CERT_AUTH));
      });
      this.form?.setChangeEvent(FORM_PARAMS.PROTOCOLS, (value: any[]) => {
        const protocols = this.util.flattenObjectArray((this.protocolsTreeSelect?.options as any[]) || this.protocolOptions || [], 'children');
        this.allProtocolsSelected = value?.length === protocols?.length;
      });
      this.form?.setChangeEvent(FORM_PARAMS.IS_USER_AUTH, (value: boolean) => {
        this.onIsUserAuthChange(value);
      });
      this.form?.setChangeEvent(FORM_PARAMS.IS_CERT_AUTH, (value: boolean) => {
        this.onIsCertAuthChange(value);
      });
    });
  }

  onStepChange(step: number, setupType = '', setupStep = '') {
    let previousStep = '';
    this.agentDownloaded = false;
    switch (setupType) {
      case CONNECTION_TYPES.MQTT_AGENT: {
        this.mqttAgentSetupStep = setupStep;
        break;
      }
      case CONNECTION_TYPES.NODE: {
        previousStep = this.nodeSetupStep;
        this.nodeSetupStep = setupStep;
        break;
      }
      default: {
        break;
      }
    }
    this.step += step;
    if (this.step >= 2) {
      this.serverErrors = null;
    }
    this.form.setControlVisibility(FORM_PARAMS.SETUP_STEP, this.step < 2);
    this.form.setControlVisibility(FORM_PARAMS.FINALIZE_STEP, this.step === 2);
    switch (setupType) {
      case CONNECTION_TYPES.MQTT_AGENT: {
        if (this.mqttAgentSetupStep !== 'download' && this.mqttAgentSetupStep !== 'guide') {
          const isTLs = this.form.getControlValue(FORM_PARAMS.INTERFACE_IS_TLS) || false;
          this.setSetupItems(isTLs);
          this.updateStepEvent.emit({
            step,
            title: this.mqttAgentSetupStep === 'config' && this.step === 1.5 ? MQTT_TITLES[this.mqttAgentSetupStep] : undefined,
          });
        } else {
          this.updateStepEvent.emit({ step: 0, title: this.step === 1.5 ? MQTT_TITLES[this.mqttAgentSetupStep] : undefined });
        }
        break;
      }
      case CONNECTION_TYPES.NODE: {
        if (this.nodeSetupStep === 'finalize') {
          this.form.disableControl(FORM_PARAMS.ENABLED);
          this.updateStepEvent.emit({ step: 0, title: NODE_TITLES[this.nodeSetupStep] });
        } else if (this.nodeSetupStep === 'config') {
          this.updateStepEvent.emit({ step: 0, title: NODE_TITLES[this.nodeSetupStep] });
        } else {
          if (!!previousStep) {
            this.nodeSetupStep = '';
            this.step = 1;
            this.updateStepEvent.emit({ step: 0, title: undefined });
          } else {
            this.updateStepEvent.emit({ step, title: undefined });
          }
        }
        break;
      }
      default: {
        this.updateStepEvent.emit({ step, title: undefined });
        break;
      }
    }
    setTimeout(() => {
      this.form.showServerErrorMessage(this.serverErrors, SERVER_ERROR_REPLACE_KEYS);
    }, 100);
  }

  removeError(formName: string) {
    this.form.getControl(formName).setErrors(null);
    this.form.getControl(formName).updateValueAndValidity();
  }

  get isCurrentStepValid() {
    const mqttFieldsValidity =
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_PORT)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_IS_TLS)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY)?.invalid &&
      !this.form?.getControl(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.invalid;
    switch (this.step) {
      case 1: {
        if (this.type !== CONNECTION_TYPES.MQTT_AGENT) {
          return (
            !!mqttFieldsValidity &&
            !!this.isTopicsValid &&
            !this.form?.getControl(FORM_PARAMS.INTERFACE_IDENTIFIER)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.PCAP_FILE)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.PCAP_FILE_PATH)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.PROTOCOLS)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.MACS_TO_IGNORE)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.IPS_TO_IGNORE)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.INTERFACE_CLIENT_ID)?.invalid &&
            !this.form?.getControl(FORM_PARAMS.INTERFACE_TOPICS)?.invalid
          );
        } else {
          return false;
        }
      }
      case 1.5: {
        if (this.mqttAgentSetupStep === 'config') {
          return !!mqttFieldsValidity && !this.form?.getControl(FORM_PARAMS.INTERFACE_CLIENT_ID)?.invalid;
        }
        if (this.mqttAgentSetupStep === 'download') {
          return (
            !!this.form.getControlValue(FORM_PARAMS.OS) &&
            !!this.form.getControlValue(FORM_PARAMS.ARCHITECTURE) &&
            !!this.form.getControlValue(FORM_PARAMS.FEATURES) &&
            !!this.form.getControlValue(FORM_PARAMS.CRYPTOGRAPHIC) &&
            !!this.form.getControlValue(FORM_PARAMS.VERSION)
          );
        }
        return false;
      }
      default: {
        return false;
      }
    }
  }

  onIsUserAuthChange(value: boolean) {
    this.form.setControlValidators(FORM_PARAMS.INTERFACE_BROKER_USERNAME, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
    if (!value) {
      this.removeError(FORM_PARAMS.INTERFACE_BROKER_USERNAME);
      this.removeError(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD);
    }
  }

  onIsCertAuthChange(value: boolean) {
    this.form.setControlValidators(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
    this.form.setControlValidators(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
    if (!value) {
      this.removeError(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE);
      this.removeError(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE);
    }
  }

  uploadEvent(event: any, formNameFile: string, formNameInput: string) {
    if (!!event.target && !!event.target.files && !!event.target.files.length) {
      this.form.isLoading = true;
      const file: any = (event.target.files as FileList).item(0);
      if (formNameInput !== FORM_PARAMS.PCAP_FILE_PATH) {
        this.getValidatedFile(file as File, formNameFile, formNameInput).subscribe((validatedFile: any) => {
          this.form.patchValue(validatedFile);
        });
      } else {
        this.form.setControlValue(FORM_PARAMS.PCAP_FILE, file);
        this.form.setControlValue(FORM_PARAMS.PCAP_FILE_PATH, file);
      }
      this.form.isLoading = false;
    } else {
      this.form.setControlValue(formNameFile, null);
      this.form.setControlValue(formNameInput, null);
    }
  }

  getValidatedFile(file: File, formNameFile: string, formNameInput?: any) {
    return new Observable((observe) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        observe.next({
          [formNameFile]: file,
          [formNameInput]: reader.result?.toString(),
        });
      };
    });
  }

  onProtocolsToggleAll(checked: boolean) {
    const nodes = this.util.flattenObjectArray((this.protocolsTreeSelect?.options as any[]) || [], 'children');
    this.form.setControlValue(FORM_PARAMS.PROTOCOLS, !!checked ? nodes : []);
  }

  onProtocolsSelectionChange() {
    const nodes = this.util.flattenObjectArray((this.protocolsTreeSelect?.options as any[]) || [], 'children');
    this.allProtocolsSelected = this.form.getControlValue(FORM_PARAMS.PROTOCOLS)?.length === nodes.length;
  }

  setProtocolOptions(value: string) {
    if (!!value) {
      const connectionProtocols = this.connectionProtocols[value] || [];
      const hierarchyProtocols = this.mappedHierarchyProtocols(this.protocols, connectionProtocols);
      const protocolOptions = this.mappedProtocolOptions(hierarchyProtocols);
      this.protocolOptions = protocolOptions;
    }
  }

  mappedHierarchyProtocols(protocols: any, connectionProtocols: string[]) {
    const newProtocols = this.util.cloneDeepObject(protocols);
    Object.entries(newProtocols).forEach(([key, value]: [string, any]) => {
      if (!connectionProtocols.includes(key)) {
        delete newProtocols[key];
      } else if (!!value.sub && !!Object.keys(value.sub).length) {
        newProtocols[key].sub = this.mappedHierarchyProtocols(value.sub, connectionProtocols);
      }
    });
    return Object.keys(newProtocols).length === 0 ? undefined : newProtocols;
  }

  mappedProtocolOptions(hierarchyProtocols: any): any {
    return Object.entries(hierarchyProtocols || {}).map(([key, value]: [string, any]) => ({
      key: key,
      label: value.name,
      expanded: true,
      children: !!value.sub && !!Object.keys(value.sub).length ? this.mappedProtocolOptions(value.sub) : undefined,
    }));
  }

  onAddChip(formName: string, formRegex: string, isBlur: boolean, value?: any) {
    const chipElement = this.chipComponents.find((chip) => chip.inputId === formName);
    if (!!chipElement) {
      const currentValue = value || chipElement.inputViewChild.nativeElement.value;
      if (!!chipElement.value?.length || !!isBlur) {
        if (!isBlur) {
          chipElement.value.pop();
        }
        const isDuplicate = ((chipElement?.value as any[]) || []).includes(currentValue.toLowerCase());
        const isValid = this.isInputChipValid(formRegex, currentValue);
        if (!isValid && !isBlur) {
          setTimeout(() => {
            chipElement.inputViewChild.nativeElement.value = currentValue;
          });
        }
        if (!!isValid && !!isBlur) {
          chipElement.inputViewChild.nativeElement.value = '';
        }
        if (!isDuplicate && !!isValid) {
          if (chipElement.value === null) {
            chipElement.value = [];
          }
          chipElement.value.push(currentValue.toLowerCase());
          this.form.getControl?.(formName)?.setValue(chipElement?.value);
        }
        const setErrorValid = this.isInputChipValid(formRegex, currentValue, true);
        if (!setErrorValid) {
          this.form.getControl?.(formName)?.setErrors({ pattern: true });
        } else {
          this.form.getControl?.(formName)?.setErrors(null);
        }
      }
    }
  }

  isInputChipValid(formRegex: string, value: any, init = false) {
    let isValid = init;
    if (!!value && typeof value === 'string') {
      const regex = new RegExp(formRegex);
      isValid = regex.test(value);
    }
    return isValid;
  }

  get isTopicsValid() {
    return (
      this.form?.getControlValue(FORM_PARAMS.TYPE) !== CONNECTION_TYPES.MQTT ||
      (this.form?.getControlValue(FORM_PARAMS.TYPE) === CONNECTION_TYPES.MQTT &&
        this.topicsFieldComponent?.topics?.length &&
        this.topicsFieldComponent?.form?.valid &&
        this.topicsFieldComponent?.topics?.length === this.topicsFieldComponent?.topicsFormArray?.length)
    );
  }

  testConnection() {
    this.form.isLoading = true;
    const formValues = { ...this.form.getRawValue() };
    const payload = this.getPayload(formValues, true);
    this.connectionSrv
      .testConnection(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          if (!!res.success) {
            this.form.setControlValue(FORM_PARAMS.STATUS, STATUSES.CONNECTED);
          } else {
            this.form.setControlValue(FORM_PARAMS.STATUS, STATUSES.CONNECTION_ERROR);
          }
        },
        error: (error) => {
          this.form.setControlValue(FORM_PARAMS.STATUS, STATUSES.CONNECTION_ERROR);
          this.form.showServerErrorMessage(error, SERVER_ERROR_REPLACE_KEYS);
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit() {
    this.form.isLoading = true;
    const formValues = { ...this.form.getRawValue() };
    const payload = this.getPayload(formValues);
    const request: Observable<any> = !this.connection
      ? this.connectionSrv.createConnection(payload)
      : this.connectionSrv.updateConnection(this.connection.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.showSuccessMessage(`Network ${!!this.connection ? 'Updated' : 'Created'} Successfully`);
          switch (this.type) {
            case CONNECTION_TYPES.MQTT_AGENT:
            case CONNECTION_TYPES.NODE: {
              this.connection = res.data;
              this.setLocalStorageEvent.emit(res.data);
              this.step = 1;
              this.form.setControlVisibility(FORM_PARAMS.SETUP_STEP, this.step < 2);
              this.form.setControlVisibility(FORM_PARAMS.FINALIZE_STEP, this.step === 2);
              this.updateStepEvent.emit({ step: 1 });
              break;
            }
            default: {
              this.closeEvent.emit(res.data);
              break;
            }
          }
          this.setMqttBrokerValues();
        },
        error: (err: any) => {
          this.serverErrors = err;
          this.form.showServerErrorMessage(this.serverErrors, SERVER_ERROR_REPLACE_KEYS);
          this.showErrorMessage(err);
        },
      });
  }

  getPayload(formValues: any, isTestConnection = false) {
    const protocols = formValues[FORM_PARAMS.PROTOCOLS]?.map((protocol: any) => protocol.key) || [];

    const isBuiltinMqttAgent = this.type === CONNECTION_TYPES.MQTT_AGENT && formValues[FORM_PARAMS.MQTT_BROKER] === MQTT_AGENT_BROKERS.BUILT_IN;

    if (this.type !== CONNECTION_TYPES.PCAP) {
      const payload = {
        [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
        [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
        [FORM_PARAMS.NAME]: formValues[FORM_PARAMS.NAME],
        [FORM_PARAMS.DESCRIPTION]: formValues[FORM_PARAMS.DESCRIPTION],
        ...(this.type !== CONNECTION_TYPES.NODE && {
          [FORM_PARAMS.PROTOCOLS]: protocols,
        }),
        ...(this.type === CONNECTION_TYPES.PHYSICAL && {
          [FORM_PARAMS.MACS_TO_IGNORE]: formValues[FORM_PARAMS.MACS_TO_IGNORE],
          [FORM_PARAMS.IPS_TO_IGNORE]: formValues[FORM_PARAMS.IPS_TO_IGNORE],
        }),
        [FORM_PARAMS.STATUS]: formValues[FORM_PARAMS.STATUS] || STATUSES.NOT_CONNECTED,
        [FORM_PARAMS.ENABLED]: formValues[FORM_PARAMS.ENABLED],
        [FORM_PARAMS.INTERFACE]: {
          [FORM_PARAMS.TYPE]: this.type,
          ...(this.type === CONNECTION_TYPES.PHYSICAL && {
            [FORM_PARAMS.INTERFACE_USE_PACKET_PROCESSOR]: true,
            [FORM_PARAMS.INTERFACE_IDENTIFIER]: formValues[FORM_PARAMS.INTERFACE_IDENTIFIER],
          }),
          ...((this.type === CONNECTION_TYPES.MQTT || this.type === CONNECTION_TYPES.MQTT_AGENT) && {
            [FORM_PARAMS.INTERFACE_BROKER_HOSTNAME]: !isBuiltinMqttAgent
              ? formValues[FORM_PARAMS.INTERFACE_BROKER_HOSTNAME]
              : MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_BROKER_HOSTNAME],
            [FORM_PARAMS.INTERFACE_BROKER_PORT]: !isBuiltinMqttAgent
              ? formValues[FORM_PARAMS.INTERFACE_BROKER_PORT]
              : MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_BROKER_PORT],
            [FORM_PARAMS.INTERFACE_IS_TLS]: !isBuiltinMqttAgent
              ? formValues[FORM_PARAMS.INTERFACE_IS_TLS]
              : MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_IS_TLS],
            [FORM_PARAMS.INTERFACE_TLS_CERTIFICATE]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.INTERFACE_IS_TLS] ? formValues[FORM_PARAMS.INTERFACE_TLS_CERTIFICATE] || null : null,
            [FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_ORIGIN_FILENAME]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.INTERFACE_IS_TLS]
                ? formValues[FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE]?.name || null
                : null,
            [FORM_PARAMS.INTERFACE_IS_ANONYMOUS]: !isBuiltinMqttAgent
              ? !formValues[FORM_PARAMS.IS_USER_AUTH]
              : MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_IS_ANONYMOUS],
            [FORM_PARAMS.INTERFACE_BROKER_USERNAME]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_USER_AUTH]
                ? formValues[FORM_PARAMS.INTERFACE_BROKER_USERNAME] || null
                : MQTT_AGENT_BUILTIN_DEFAULT_VALUES[FORM_PARAMS.INTERFACE_BROKER_USERNAME],
            [FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_USER_AUTH]
                ? formValues[FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD] || null
                : null,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_CERT_AUTH] ? formValues[FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT] || null : null,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_ORIGIN_FILENAME]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_CERT_AUTH]
                ? formValues[FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE]?.name || null
                : null,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_CERT_AUTH] ? formValues[FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY] || null : null,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_ORIGIN_FILENAME]:
              !isBuiltinMqttAgent && !!formValues[FORM_PARAMS.IS_CERT_AUTH]
                ? formValues[FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE]?.name || null
                : null,
          }),
          ...(this.type === CONNECTION_TYPES.MQTT && {
            [FORM_PARAMS.INTERFACE_CLIENT_ID]: formValues[FORM_PARAMS.INTERFACE_CLIENT_ID] || '',
            [FORM_PARAMS.INTERFACE_TOPICS]: (this.topics || []).map((topic) => ({
              ...topic,
              id: undefined,
              [FORM_PARAMS.PREPROCESSING_LAMBDA]: topic[FORM_PARAMS.PREPROCESSING_LAMBDA] || null,
              [FORM_PARAMS.PROCESSING_LAMBDA]: topic[FORM_PARAMS.PROCESSING_LAMBDA] || null,
            })),
          }),
        },
      };
      return !isTestConnection
        ? payload
        : {
            ...payload[FORM_PARAMS.INTERFACE],
            [FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_ORIGIN_FILENAME]: undefined,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_ORIGIN_FILENAME]: undefined,
            [FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_ORIGIN_FILENAME]: undefined,
          };
    } else {
      const formData = new FormData();
      if (!isTestConnection) {
        formData.append(FORM_PARAMS.ORGANIZATION_ID, this.breadcrumbConfig?.organizationId);
        formData.append(FORM_PARAMS.PROJECT_ID, this.breadcrumbConfig?.projectId);
        formData.append(FORM_PARAMS.NAME, formValues[FORM_PARAMS.NAME]);
        formData.append(FORM_PARAMS.DESCRIPTION, formValues[FORM_PARAMS.DESCRIPTION]);
        formData.append(FORM_PARAMS.PROTOCOLS, JSON.stringify(protocols));
        formData.append(FORM_PARAMS.STATUS, formValues[FORM_PARAMS.STATUS] || STATUSES.NOT_CONNECTED);
        formData.append(FORM_PARAMS.ENABLED, formValues[FORM_PARAMS.ENABLED]);
        formData.append(FORM_PARAMS.RELOAD_PCAP, 'false');
        formData.append(FORM_PARAMS.MACS_TO_IGNORE, JSON.stringify(formValues[FORM_PARAMS.MACS_TO_IGNORE]));
        formData.append(FORM_PARAMS.IPS_TO_IGNORE, JSON.stringify(formValues[FORM_PARAMS.IPS_TO_IGNORE]));
      }
      formData.append(FORM_PARAMS.TYPE, this.type);
      formData.append(FORM_PARAMS.INTERFACE_USE_PACKET_PROCESSOR, 'true');
      if (!!formValues[FORM_PARAMS.PCAP_FILE_PATH]) {
        formData.append(FORM_PARAMS.PCAP_FILE_PATH, formValues[FORM_PARAMS.PCAP_FILE_PATH]);
      }
      return formData;
    }
  }

  downloadAgent() {
    this.form.isLoading = true;
    const formValues = { ...this.form.getRawValue() };
    const fileName = MICROAGENT_FILES[formValues[FORM_PARAMS.OS]]?.[formValues[FORM_PARAMS.ARCHITECTURE]]?.[formValues[FORM_PARAMS.FEATURES]] || '';
    if (!!fileName) {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = `/assets/agents/${fileName}`;
      link.click();
      this.showSuccessMessage('Initiating MicroAgent Download');
      this.agentDownloaded = true;
    } else {
      this.showErrorMessage('File not exist at the moment.', 'warn');
    }
    this.form.isLoading = false;
  }

  onChangeSkipSetupAgent(checked: any) {
    const validators = !checked ? [VALIDATOR_TYPE.REQUIRED] : [];
    this.form.setControlValidators(FORM_PARAMS.OS, validators);
    this.form.setControlValidators(FORM_PARAMS.ARCHITECTURE, validators);
    this.form.setControlValidators(FORM_PARAMS.FEATURES, validators);
    this.form.setControlValidators(FORM_PARAMS.CRYPTOGRAPHIC, validators);
    this.form.setControlValidators(FORM_PARAMS.VERSION, validators);
  }

  openTokenForm() {
    const dialogRef = this.dialogSrv.open(AccessTokenFormComponent, {
      data: { connection: this.connection },
      header: `Add Token`,
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialogRef.onClose.subscribe((rs) => {
      if (!!rs) {
        this.connection = rs;
      }
    });
  }

  goToDocumentation() {
    const customDomain: any = localStorage.getItem('domain');
    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
  }

  goToInstallationGuide() {
    const customDomain: any = localStorage.getItem('domain');
    window.open(`${DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain)}usage/microagents/00_installation_upgrading_removal.html`, '_blank');
  }

  openWiresharkDocs() {
    window.open('https://www.wireshark.org/docs/wsug_html_chunked/ChapterCapture.html', '_blank');
  }

  setMqttBrokerValues() {
    const customDomain: any = localStorage.getItem('domain');
    this.mqttBrokerAddress = `ssl://${customDomain}:${this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_BROKER_PORT]}`;
    this.mqttBrokerPassword = this.connection?.[FORM_PARAMS.INTERFACE]?.[FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD];
  }

  /**
   * Copy the value of a field
   * @param value value of he field
   * @param name name of the field
   */
  copyValue(value: any, name: string) {
    this.util.copyToClipboard(value);
    this.showSuccessMessage(`${name} copied`);
  }

  get currentHostname() {
    return location.origin;
  }

  /**
   * Copy project id
   */
  copyProjectID() {
    this.util.copyToClipboard(this.breadcrumbConfig?.projectId);
    this.showSuccessMessage('Project ID copied');
  }
}
